import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase/config';
import { GoogleAuthProvider, signInWithPopup, signOut, browserPopupRedirectResolver } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAdminStatus = (user) => {
    if (user && user.email === "rubenkohn67@gmail.com") {
      setIsAdmin(true);
      sessionStorage.setItem('isAdmin', 'true');
    } else {
      setIsAdmin(false);
      sessionStorage.removeItem('isAdmin');
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Use browserPopupRedirectResolver to handle the popup in a more secure way
      const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
      checkAdminStatus(result.user);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // Only throw error if it's not related to popup handling
      if (!error.message.includes('Cross-Origin-Opener-Policy') && 
          !error.message.includes('closed') &&
          !error.message.includes('popup')) {
        throw error;
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      sessionStorage.removeItem('isAdmin');
      setIsAdmin(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    // Set up auth state listener
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      
      // Check admin status from sessionStorage first
      const savedAdminStatus = sessionStorage.getItem('isAdmin') === 'true';
      
      if (user && (savedAdminStatus || user.email === "rubenkohn67@gmail.com")) {
        setIsAdmin(true);
        sessionStorage.setItem('isAdmin', 'true');
      } else {
        setIsAdmin(false);
        sessionStorage.removeItem('isAdmin');
      }
      
      setLoading(false);
    });

    // Check if we have a saved admin status on mount
    const savedAdminStatus = sessionStorage.getItem('isAdmin') === 'true';
    if (savedAdminStatus) {
      setIsAdmin(true);
    }

    // Cleanup subscription
    return () => {
      unsubscribe();
      // Clear session storage on unmount if user is not authenticated
      if (!auth.currentUser) {
        sessionStorage.removeItem('isAdmin');
      }
    };
  }, []);

  const value = {
    currentUser,
    isAdmin,
    signInWithGoogle,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

import { useTranslation } from 'react-i18next';
import { PencilIcon, PlusIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/config';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import {
  BookCard,
  RecommendationCard,
  CategoryFilter
} from '../components/books';

export default function Books() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [myBooks, setMyBooks] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        if (process.env.NODE_ENV === 'development') {
          const localBooks = JSON.parse(localStorage.getItem('books') || '[]');
          const myBooksData = localBooks
            .filter(book => book.type === 'my_book')
            .map(book => ({
              ...book,
              category: book.category || 'other',
              title: book.title || 'Untitled Book',
              author: book.author || 'Unknown Author',
              description: book.description || '',
              year: book.year || new Date().getFullYear().toString(),
              image: book.image || '',
              link: book.link || ''
            }));
          const recommendationsData = localBooks
            .filter(book => book.type !== 'my_book')
            .map(book => ({
              ...book,
              category: book.category || 'other',
              title: book.title || 'Untitled Book',
              author: book.author || 'Unknown Author',
              description: book.description || '',
              year: book.year || new Date().getFullYear().toString(),
              image: book.image || '',
              link: book.link || ''
            }))
            .sort((a, b) => parseInt(b.year) - parseInt(a.year)); // Sort by year descending
          setMyBooks(myBooksData);
          setRecommendations(recommendationsData);
          setLoading(false);
          return;
        }

        const booksQuery = query(collection(db, 'books'));
        const querySnapshot = await getDocs(booksQuery);
        
        const myBooksData = [];
        const recommendationsData = [];
        
        querySnapshot.forEach((doc) => {
          const bookData = {
            id: doc.id,
            ...doc.data(),
            // Ensure required fields exist
            category: doc.data().category || 'other',
            title: doc.data().title || 'Untitled Book',
            author: doc.data().author || 'Unknown Author',
            description: doc.data().description || '',
            year: doc.data().year || new Date().getFullYear().toString(),
            image: doc.data().image || '',
            link: doc.data().link || '',
            type: doc.data().type || 'recommendation'
          };

          if (bookData.type === 'my_book') {
            myBooksData.push(bookData);
          } else {
            recommendationsData.push(bookData);
          }
        });

        // Sort recommendations by year in descending order
        recommendationsData.sort((a, b) => parseInt(b.year) - parseInt(a.year));

        setMyBooks(myBooksData);
        setRecommendations(recommendationsData);
      } catch (error) {
        console.error('Error fetching books:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  const handleDelete = async (bookId) => {
    try {
      if (process.env.NODE_ENV === 'development') {
        const localBooks = JSON.parse(localStorage.getItem('books') || '[]');
        const updatedBooks = localBooks.filter(book => book.id !== bookId);
        localStorage.setItem('books', JSON.stringify(updatedBooks));
        
        setMyBooks(currentBooks => currentBooks.filter(book => book.id !== bookId));
        setRecommendations(currentRecommendations => 
          currentRecommendations.filter(book => book.id !== bookId)
        );
        return;
      }

      await deleteDoc(doc(db, 'books', bookId));
      setMyBooks(currentBooks => currentBooks.filter(book => book.id !== bookId));
      setRecommendations(currentRecommendations => 
        currentRecommendations.filter(book => book.id !== bookId)
      );
    } catch (error) {
      console.error('Error deleting book:', error);
    }
  };

  const filteredRecommendations = selectedCategory === 'all'
    ? recommendations
    : recommendations.filter(book => (book.category || 'other').toLowerCase() === selectedCategory);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.books.loading')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        {/* Header with Add Button */}
        {isAdmin && (
          <div className="flex justify-end mb-8">
            <button
              onClick={() => navigate('/books/create')}
              className="flex items-center space-x-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
            >
              <PlusIcon className="w-5 h-5" />
              <span>{t('pages.books.create_button')}</span>
            </button>
          </div>
        )}

        {/* My Books Section */}
        <div className="mb-16">
          <div className="flex items-center space-x-2 mb-8">
            <PencilIcon className="w-6 h-6 text-primary" />
            <h2 className="text-3xl font-bold">{t('pages.books.sections.my_books.title')}</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {myBooks.map(book => (
              <BookCard 
                key={book.id} 
                book={book}
                isAdmin={isAdmin}
                onDelete={handleDelete}
                onEdit={() => navigate(`/books/edit/${book.id}`)}
              />
            ))}
          </div>
        </div>

        {/* Recommendations Section */}
        <div>
          <div className="flex items-center space-x-2 mb-8">
            <BookmarkIcon className="w-6 h-6 text-accent" />
            <h2 className="text-3xl font-bold">{t('pages.books.sections.recommended.title')}</h2>
          </div>

          <CategoryFilter 
            selectedCategory={selectedCategory}
            onCategoryChange={setSelectedCategory}
          />

          <div className="grid grid-cols-1 gap-6">
            {filteredRecommendations.map(book => (
              <RecommendationCard 
                key={book.id} 
                book={book}
                isAdmin={isAdmin}
                onDelete={handleDelete}
                onEdit={() => navigate(`/books/edit/${book.id}`)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

const BookSection = ({ bookCover }) => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg">
      <div className="md:flex">
        <div className="md:w-1/3 relative overflow-hidden">
          <img 
            src={bookCover} 
            alt="Book Cover" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-transparent md:hidden" />
        </div>
        <div className="md:w-2/3 p-8">
          <h3 className="text-2xl font-bold mb-4">{t('pages.about.book.title')}</h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            {t('pages.about.book.subtitle')}
          </p>
          <ul className="space-y-4">
            {t('pages.about.book.achievements', { returnObjects: true }).map((achievement, index) => (
              <li key={index} className="flex items-start gap-3 text-gray-600 dark:text-gray-300">
                <span className="w-1.5 h-1.5 mt-2 bg-primary rounded-full flex-shrink-0" />
                <span>{achievement}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BookSection;

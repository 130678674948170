import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, TrashIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { PdfPreviewModal } from './PdfPreviewModal';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import TranslationWarning from '../TranslationWarning';

export const BookCard = ({ book, isAdmin, onDelete, onEdit }) => {
  const { t, i18n } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDelete = async () => {
    await onDelete(book.id);
    setShowDeleteConfirm(false);
  };

  const isTranslationMissing = i18n.language === 'en' && (!book.titleEn || !book.descriptionEn);
  const displayTitle = i18n.language === 'en' && book.titleEn ? book.titleEn : book.title;
  const displayDescription = i18n.language === 'en' && book.descriptionEn ? book.descriptionEn : book.description;

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-[0_0_15px_rgba(59,130,246,0.3)] transition-all duration-300 transform hover:scale-[1.02] group">
        <div className="relative pt-[80%]">
          <img 
            src={book.cover} 
            alt={displayTitle}
            className="absolute inset-0 w-full h-full object-contain bg-gray-100 dark:bg-gray-700 transition-all duration-300 group-hover:brightness-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-4 left-4 right-4">
            <div className="flex items-center space-x-2">
              <span className="px-2 py-1 bg-primary/90 text-white text-sm rounded-full">
                {book.status || t('pages.books.status.published')}
              </span>
              <span className="text-white text-sm">
                {book.year}
              </span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-white mt-2">
                {displayTitle}
              </h3>
              {isTranslationMissing && <TranslationWarning />}
            </div>
          </div>
          {isAdmin && (
            <div className="absolute top-4 right-4 flex space-x-2">
              <button
                onClick={onEdit}
                className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
              >
                <PencilSquareIcon className="w-5 h-5 text-primary" />
              </button>
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
              >
                <TrashIcon className="w-5 h-5 text-red-500" />
              </button>
            </div>
          )}
        </div>
        <div className="p-6">
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            {displayDescription}
          </p>
          {book.previewPdf && (
            <button
              onClick={() => setShowPreview(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-primary/10 text-primary rounded-lg hover:bg-primary/20 transition-colors"
            >
              <DocumentTextIcon className="w-5 h-5" />
              <span>{t('pages.books.preview_button')}</span>
            </button>
          )}
        </div>
      </div>
      {showPreview && (
        <PdfPreviewModal book={book} onClose={() => setShowPreview(false)} />
      )}
      {showDeleteConfirm && (
        <DeleteConfirmModal 
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};


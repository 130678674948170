import { useTranslation } from 'react-i18next';
import {
  HashtagIcon,
  CpuChipIcon,
  UserGroupIcon,
  ChartBarIcon,
  AcademicCapIcon
} from '@heroicons/react/24/outline';
import { NetworkIcon } from '../icons/NetworkIcon';

const categories = [
  { name: 'all', icon: HashtagIcon },
  { name: 'ai', icon: CpuChipIcon },
  { name: 'big_data', icon: ChartBarIcon },
  { name: 'network_science', icon: NetworkIcon },
  { name: 'sociology', icon: UserGroupIcon },
  { name: 'psychology', icon: AcademicCapIcon },
];

export const CategoryFilter = ({ selectedCategory, onCategoryChange }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap justify-center gap-4 mb-8">
      {categories.map(({ name, icon: Icon }) => (
        <button
          key={name}
          onClick={() => onCategoryChange(name)}
          className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300 ${
            selectedCategory === name
              ? 'bg-primary text-white shadow-lg'
              : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:shadow-md'
          }`}
        >
          <Icon className="w-5 h-5" />
          <span>
            {t(`pages.books.categories.${name}`)}
          </span>
        </button>
      ))}
    </div>
  );
}

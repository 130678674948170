import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  BeakerIcon, 
  BookOpenIcon, 
  CameraIcon, 
  GlobeAltIcon,
  BriefcaseIcon,
  UserIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';
import { db } from '../firebase/config';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import profileImage from '../assets/images/profile.jpg';
import ParticleBackground from '../components/ParticleBackground';

// Function to strip HTML tags and get plain text
const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const FeatureCard = ({ to, Icon, title, description, color, isExternal }) => {
  const { t } = useTranslation();
  const Component = isExternal ? 'a' : Link;
  const props = isExternal ? { href: to, target: "_blank", rel: "noopener noreferrer" } : { to };

  return (
    <Component 
      {...props}
      className="group relative overflow-hidden rounded-3xl bg-white dark:bg-gray-800 p-8 shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-500 ease-out"
    >
      <div className="relative z-10">
        <div className="relative">
          <Icon className={`w-12 h-12 ${color} mb-6 transform group-hover:scale-110 transition-transform duration-500 ease-out`} />
          <div className="absolute -inset-2 rounded-full blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white transition-colors duration-300">{title}</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4 group-hover:text-gray-900 dark:group-hover:text-white transition-colors duration-300">
          {description}
        </p>
        <div className={`inline-flex items-center ${color} transform translate-x-0 group-hover:translate-x-2 transition-all duration-500 ease-out`}>
          <span className="font-medium">{t('pages.home.features.explore')}</span>
          <ArrowRightIcon className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-500 ease-out" />
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-transparent via-gray-100/5 to-gray-100/10 dark:via-gray-700/5 dark:to-gray-700/10 opacity-0 group-hover:opacity-100 transition-all duration-500 ease-out"></div>
    </Component>
  );
};

const LatestArticle = ({ article }) => {
  const { t } = useTranslation();
  
  if (!article) return null;

  return (
    <Link to={`/blog/${article.id}`} className="block group">
      <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2">
        <div className="aspect-video relative overflow-hidden">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-all duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
            <div className="absolute bottom-4 left-4 right-4">
              <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                {t(`pages.blog.categories.${article.category}`)}
              </span>
            </div>
          </div>
        </div>
        <div className="p-6">
          <h3 className="text-xl font-bold mb-2 text-gray-900 dark:text-white group-hover:text-primary dark:group-hover:text-primary transition-colors duration-300">
            {article.title}
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-4 group-hover:text-gray-900 dark:group-hover:text-gray-200 transition-colors duration-300">
            {stripHtml(article.content).substring(0, 150)}...
          </p>
          <div className="flex justify-between items-center text-sm text-gray-500 dark:text-gray-400">
            <span>
              {new Date(article.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
            <span>{article.readTime}</span>
          </div>
          <div className="mt-4 flex items-center text-primary font-medium opacity-0 group-hover:opacity-100 transform translate-x-2 group-hover:translate-x-0 transition-all duration-500">
            <span>{t('pages.blog.read_more')}</span>
            <ArrowRightIcon className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function Home() {
  const { t } = useTranslation();
  const [latestArticle, setLatestArticle] = useState(null);

  useEffect(() => {
    const fetchLatestArticle = async () => {
      try {
        if (process.env.NODE_ENV === 'development') {
          const localPosts = JSON.parse(localStorage.getItem('posts') || '[]');
          if (localPosts.length > 0) {
            // Sort by date in descending order and get the latest
            const sortedPosts = localPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
            setLatestArticle(sortedPosts[0]);
            return;
          }
        }

        const q = query(
          collection(db, 'posts'),
          orderBy('date', 'desc'),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setLatestArticle({
            id: doc.id,
            ...doc.data()
          });
        }
      } catch (error) {
        console.error('Error fetching latest article:', error);
      }
    };

    fetchLatestArticle();
  }, []);

  const features = [
    {
      to: '/about',
      Icon: UserIcon,
      title: t('pages.home.features.about.title'),
      description: t('pages.home.features.about.description'),
      color: 'text-blue-500'
    },
    {
      to: '/tech',
      Icon: BeakerIcon,
      title: t('pages.home.features.tech.title'),
      description: t('pages.home.features.tech.description'),
      color: 'text-primary'
    },
    {
      to: '/projects',
      Icon: BriefcaseIcon,
      title: t('pages.home.features.projects.title'),
      description: t('pages.home.features.projects.description'),
      color: 'text-green-500'
    },
    {
      to: '/books',
      Icon: BookOpenIcon,
      title: t('pages.home.features.books.title'),
      description: t('pages.home.features.books.description'),
      color: 'text-accent'
    },
    {
      to: '/photography',
      Icon: CameraIcon,
      title: t('pages.home.features.photography.title'),
      description: t('pages.home.features.photography.description'),
      color: 'text-purple-500'
    },
    {
      to: 'https://www.linkedin.com/in/rubenkohn/',
      Icon: GlobeAltIcon,
      title: t('pages.home.features.social.title'),
      description: t('pages.home.features.social.description'),
      color: 'text-indigo-500',
      isExternal: true
    }
  ];

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 overflow-hidden">
      <ParticleBackground />
      
      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center justify-center pt-16 md:pt-0">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent"></div>
        </div>

        <div className="container-custom relative z-10">
          <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-16 px-4">
            {/* Text Content */}
            <div className="flex-1 text-center lg:text-left mt-16 md:mt-0">
              <div className="mb-6 inline-block animate-fade-in">
                <span className="px-4 py-2 rounded-full bg-primary/10 text-primary text-sm font-medium">
                  {t('pages.home.hero.role')}
                </span>
              </div>
              
              <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-fade-in">
                <span className="bg-gradient-to-r from-primary via-purple-500 to-accent bg-clip-text text-transparent">
                  Ruben Kohn
                </span>
              </h1>
              
              <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-12 animate-fade-in animation-delay-200 max-w-2xl leading-relaxed">
                {t('pages.home.hero.description')}
              </p>

              <div className="flex flex-wrap justify-center lg:justify-start gap-4 animate-fade-in animation-delay-400">
                <Link 
                  to="/about" 
                  className="group relative px-8 py-4 rounded-full bg-primary text-white hover:bg-primary/90 transition-all shadow-lg hover:shadow-xl hover:-translate-y-0.5 duration-300 ease-out"
                >
                  <span className="relative z-10">{t('pages.home.hero.cta.about')}</span>
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-primary to-accent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                </Link>
                <Link 
                  to="/projects" 
                  className="px-8 py-4 rounded-full border-2 border-gray-300 dark:border-gray-700 hover:border-primary dark:hover:border-primary text-gray-700 dark:text-gray-300 hover:text-primary dark:hover:text-primary transition-all duration-300 ease-out"
                >
                  {t('pages.home.hero.cta.work')}
                </Link>
              </div>
            </div>

            {/* Profile Image */}
            <div className="relative flex-1 animate-fade-in animation-delay-300">
              <div className="relative w-64 h-64 md:w-80 md:h-80 mx-auto lg:mr-0">
                <div className="absolute -inset-4 bg-gradient-to-br from-primary to-accent rounded-full animate-pulse opacity-20" style={{ animationDuration: '3s' }}></div>
                <div className="relative w-full h-full rounded-full overflow-hidden border-4 border-white dark:border-gray-800 shadow-2xl">
                  <img 
                    src={profileImage} 
                    alt="Ruben Kohn" 
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500 ease-out"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div id="content" className="container-custom py-24">
        {/* Latest Article */}
        {latestArticle && (
          <div className="mb-24">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
              <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
                {t('pages.home.latest_article.title')}
              </span>
            </h2>
            <div className="max-w-3xl mx-auto">
              <LatestArticle article={latestArticle} />
            </div>
          </div>
        )}

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature) => (
            <FeatureCard key={feature.to} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}

import airflowLogo from '../assets/images/airflow.png';
import bashLogo from '../assets/images/bash.png';
import dbtLogo from '../assets/images/dbt.png';
import dockerLogo from '../assets/images/docker.png';
import firebaseLogo from '../assets/images/firebase.png';
import gitlabLogo from '../assets/images/gitlab.png';
import lookerLogo from '../assets/images/looker.png';
import pythonLogo from '../assets/images/python.png';
import reactLogo from '../assets/images/react.png';
import siffletLogo from '../assets/images/sifflet.png';
import tailwindLogo from '../assets/images/tailwind.png';
import terraformLogo from '../assets/images/terraform.png';
import bigqueryLogo from '../assets/images/Bigquery.png';
import gcpLogo from '../assets/images/Gcp.png';

export const techDetails = {
  python: {
    name: 'Python',
    logo: pythonLogo,
    rating: 5,
    description: 'Extensive experience in data engineering and analytics frameworks',
    projects: [
      'Built comprehensive data monitoring frameworks',
      'Developed data transformation projects',
      'Created data analysis and visualization solutions',
      'Implemented Cloud Run services and Airflow DAGs'
    ]
  },
  airflow: {
    name: 'Airflow',
    logo: airflowLogo,
    rating: 2,
    description: 'Experience in DAG creation and scheduling',
    projects: [
      'Created and maintained DAG workflows',
      'Implemented task scheduling',
      'Managed workflow dependencies',
      'Basic pipeline monitoring'
    ]
  },
  dbt: {
    name: 'DBT',
    logo: dbtLogo,
    rating: 4,
    description: 'Advanced data transformation with Looker integration',
    projects: [
      'Created YouTube tutorials on DBT usage',
      'Integrated DBT models with Looker',
      'Implemented data modeling best practices',
      'Extensive documentation and knowledge sharing'
    ]
  },
  terraform: {
    name: 'Terraform',
    logo: terraformLogo,
    rating: 2,
    description: 'Infrastructure as Code focusing on Looker deployment',
    projects: [
      'Implemented Looker provider configurations',
      'Managed CI/CD pipelines',
      'Created reusable Terraform modules',
      'Automated project configurations'
    ]
  },
  gitlab: {
    name: 'GitLab',
    logo: gitlabLogo,
    rating: 4,
    description: 'CI/CD and version control management',
    projects: [
      'Developed CI/CD pipelines',
      'Managed code repositories',
      'Automated deployment processes',
      'Implemented code review workflows'
    ]
  },
  looker: {
    name: 'Looker',
    logo: lookerLogo,
    rating: 5,
    description: 'Technical Lead and Administrator for enterprise implementation',
    projects: [
      'Led framework development and Auchan integration',
      'Provided admin support for explorers and developers',
      'Conducted user training across all levels',
      'Managed platform architecture and best practices'
    ]
  },
  sifflet: {
    name: 'Sifflet',
    logo: siffletLogo,
    rating: 5,
    description: 'Data quality monitoring and management',
    projects: [
      'Led technical migration',
      'Developed "Data Quality as Code"',
      'Implemented monitoring systems',
      'Created custom quality checks'
    ]
  },
  docker: {
    name: 'Docker',
    logo: dockerLogo,
    rating: 2,
    description: 'Basic containerization with cloud artifacts',
    projects: [
      'Created images using Artifact Registry',
      'Utilized existing images for projects',
      'Basic container management',
      'Cloud-based image deployment'
    ]
  },
  react: {
    name: 'React',
    logo: reactLogo,
    rating: 2,
    description: 'Frontend development with React',
    projects: [
      'Built interactive web applications',
      'Implemented component libraries',
      'Created responsive interfaces',
      'Developed custom hooks'
    ]
  },
  tailwind: {
    name: 'Tailwind',
    logo: tailwindLogo,
    rating: 2,
    description: 'Modern CSS framework for styling',
    projects: [
      'Designed responsive layouts',
      'Created custom component systems',
      'Implemented dark mode support',
      'Built reusable UI components'
    ]
  },
  firebase: {
    name: 'Firebase',
    logo: firebaseLogo,
    rating: 2,
    description: 'Backend services and hosting',
    projects: [
      'Implemented authentication',
      'Managed real-time databases',
      'Deployed web applications',
      'Integrated cloud functions'
    ]
  },
  bash: {
    name: 'Bash',
    logo: bashLogo,
    rating: 3,
    description: 'Shell scripting and automation',
    projects: [
      'Automated deployment processes',
      'Created system utilities',
      'Managed server configurations',
      'Built automation scripts'
    ]
  },
  bigquery: {
    name: 'BigQuery',
    logo: bigqueryLogo,
    rating: 4,
    description: 'Cloud data warehouse and analytics',
    projects: [
      'Designed data warehouse architecture',
      'Optimized query performance',
      'Implemented data partitioning',
      'Created complex SQL queries'
    ]
  },
  gcp: {
    name: 'Google Cloud',
    logo: gcpLogo,
    rating: 3,
    description: 'Cloud platform services and infrastructure',
    projects: [
      'Managed cloud resources',
      'Implemented cloud storage solutions',
      'Configured networking and security',
      'Deployed cloud functions'
    ]
  }
};

import { useState } from 'react';
import { auth } from '../firebase/config';

export const useBookUpload = () => {
  const [uploadProgress, setUploadProgress] = useState({
    cover: 0,
    pdf: 0
  });
  const [error, setError] = useState(null);

  const uploadFile = async (file, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        setError(null);
        setUploadProgress(prev => ({ ...prev, [type]: 10 }));
        
        // Get the current user's ID token
        const token = await auth.currentUser.getIdToken();

        // Convert file to base64
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const base64data = reader.result;
            
            // Call the Cloud Function
            const response = await fetch('https://us-central1-kohnnect-prod-2024.cloudfunctions.net/uploadImage', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                file: base64data,
                contentType: file.type,
                folder: type === 'cover' ? 'books/covers' : 'books/previews'
              })
            });

            if (!response.ok) {
              throw new Error('Upload failed');
            }

            setUploadProgress(prev => ({ ...prev, [type]: 90 }));
            const data = await response.json();
            setUploadProgress(prev => ({ ...prev, [type]: 100 }));
            resolve(data.url);
          } catch (error) {
            console.error('Upload error:', error);
            setError(error.message);
            reject(error);
          }
        };

        reader.onerror = (error) => {
          setError(error.message);
          reject(error);
        };
        
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Token error:', error);
        setError(error.message);
        reject(error);
      }
    });
  };

  const uploadCover = async (file) => {
    return uploadFile(file, 'cover');
  };

  const uploadPdf = async (file) => {
    return uploadFile(file, 'pdf');
  };

  const getTotalProgress = () => {
    const total = uploadProgress.cover + uploadProgress.pdf;
    return Math.round(total / 2);
  };

  const resetProgress = () => {
    setUploadProgress({ cover: 0, pdf: 0 });
    setError(null);
  };

  return {
    uploadCover,
    uploadPdf,
    uploadProgress,
    totalProgress: getTotalProgress(),
    error,
    setError,
    resetProgress
  };
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import page translations
import enHome from './pages/home/en.json';
import frHome from './pages/home/fr.json';
import enAbout from './pages/about/en.json';
import frAbout from './pages/about/fr.json';
import enBlog from './pages/blog/en.json';
import frBlog from './pages/blog/fr.json';
import enProjects from './pages/projects/en.json';
import frProjects from './pages/projects/fr.json';
import enBooks from './pages/books/en.json';
import frBooks from './pages/books/fr.json';
import enPhotography from './pages/photography/en.json';
import frPhotography from './pages/photography/fr.json';
import enNavigation from './pages/navigation/en.json';
import frNavigation from './pages/navigation/fr.json';

const resources = {
  en: {
    translation: {
      pages: {
        home: enHome,
        about: enAbout,
        blog: enBlog,
        projects: enProjects,
        books: enBooks,
        photography: enPhotography,
        navigation: enNavigation
      }
    }
  },
  fr: {
    translation: {
      pages: {
        home: frHome,
        about: frAbout,
        blog: frBlog,
        projects: frProjects,
        books: frBooks,
        photography: frPhotography,
        navigation: frNavigation
      }
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    returnObjects: true
  });

export default i18n;

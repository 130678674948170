import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, TrashIcon, ClockIcon } from '@heroicons/react/24/outline';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import TranslationWarning from '../TranslationWarning';

const formatReadingTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours === 0) {
    return `${minutes} min`;
  } else if (remainingMinutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${remainingMinutes}min`;
  }
};

export const RecommendationCard = ({ book, isAdmin, onDelete, onEdit }) => {
  const { t, i18n } = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDelete = async () => {
    await onDelete(book.id);
    setShowDeleteConfirm(false);
  };

  const isTranslationMissing = i18n.language === 'en' && (!book.titleEn || !book.descriptionEn);
  const displayTitle = i18n.language === 'en' && book.titleEn ? book.titleEn : book.title;
  const displayDescription = i18n.language === 'en' && book.descriptionEn ? book.descriptionEn : book.description;

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-[0_0_15px_rgba(59,130,246,0.3)] transition-all duration-300 transform hover:scale-[1.02] group">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full sm:w-1/3 relative aspect-[3/4] sm:aspect-auto">
            <img 
              src={book.cover} 
              alt={displayTitle}
              className="w-full h-full object-cover sm:object-contain bg-gray-100 dark:bg-gray-700 transition-all duration-300 group-hover:brightness-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            <div className="absolute bottom-4 left-4 right-4">
              {book.year && (
                <span className="text-white text-sm">
                  {book.year}
                </span>
              )}
            </div>
            {isAdmin && (
              <div className="absolute top-4 right-4 flex space-x-2">
                <button
                  onClick={onEdit}
                  className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
                >
                  <PencilSquareIcon className="w-5 h-5 text-primary" />
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
                >
                  <TrashIcon className="w-5 h-5 text-red-500" />
                </button>
              </div>
            )}
          </div>
          <div className="w-full sm:w-2/3 p-4 sm:p-6">
            <div className="flex items-center justify-between mb-2">
              <span className="px-2 py-1 bg-accent/10 text-accent text-sm rounded-full">
                {t(`pages.blog.categories.${book.category.toLowerCase()}`)}
              </span>
              <div className="flex items-center space-x-1 text-gray-600 dark:text-gray-400">
                <ClockIcon className="w-4 h-4" />
                <span className="text-sm">{formatReadingTime(book.readingTime || 15)}</span>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-1">
                {displayTitle}
              </h3>
              {isTranslationMissing && <TranslationWarning />}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              {t('pages.books.labels.by_author')} {book.author}
            </p>
            <p className="text-gray-600 dark:text-gray-300 line-clamp-3 sm:line-clamp-none">
              {displayDescription}
            </p>
          </div>
        </div>
      </div>
      {showDeleteConfirm && (
        <DeleteConfirmModal 
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

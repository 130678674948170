import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TechGrid = ({ items, onTechClick, type = 'tech' }) => {
  const { t } = useTranslation();
  
  if (!items) return null;
  
  return (
    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4">
      {Object.entries(items).map(([key, item]) => (
        <motion.button
          key={key}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="group relative bg-white dark:bg-gray-800 rounded-xl p-4 shadow-lg hover:shadow-xl transition-all duration-300"
          onClick={() => onTechClick({ ...item, key, type })}
        >
          <img
            src={item.logo}
            alt={t(`pages.about.${type}.${key}.name`)}
            className="w-full h-16 object-contain filter transition-all duration-300"
          />
          <p className="mt-2 text-sm text-center font-medium text-gray-600 dark:text-gray-300">
            {t(`pages.about.${type}.${key}.name`)}
          </p>
        </motion.button>
      ))}
    </div>
  );
};

export default TechGrid;

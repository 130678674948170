import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase/config';
import { useAdmin } from '../hooks/useAdmin';

export default function EditProject() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'website',
    technologies: '',
    features: '',
    link: '',
    image: null,
    currentImageUrl: ''
  });

  useEffect(() => {
    const fetchProject = async () => {
      try {
        // En mode développement, chercher d'abord dans le localStorage
        if (process.env.NODE_ENV === 'development') {
          const localProjects = JSON.parse(localStorage.getItem('projects') || '[]');
          const project = localProjects.find(p => p.id === projectId);
          if (project) {
            setFormData({
              title: project.title,
              description: project.description,
              type: project.type,
              technologies: project.technologies.join(', '),
              features: project.features.join(', '),
              link: project.link || '',
              image: null,
              currentImageUrl: project.image
            });
            return;
          }
        }

        // Si pas trouvé dans le localStorage ou en production, utiliser Firestore
        const projectDoc = await getDoc(doc(db, 'projects', projectId));
        if (projectDoc.exists()) {
          const data = projectDoc.data();
          setFormData({
            title: data.title,
            description: data.description,
            type: data.type,
            technologies: data.technologies.join(', '),
            features: data.features.join(', '),
            link: data.link || '',
            image: null,
            currentImageUrl: data.image
          });
        } else {
          navigate('/projects');
        }
      } catch (error) {
        console.error('Error fetching project:', error);
        navigate('/projects');
      }
    };

    if (isAdmin) {
      fetchProject();
    } else {
      navigate('/projects');
    }
  }, [projectId, navigate, isAdmin]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      // Convertir l'image en data URL pour le localStorage
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          image: file,
          currentImageUrl: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = formData.currentImageUrl;

      // En mode développement, mettre à jour le localStorage
      if (process.env.NODE_ENV === 'development') {
        const localProjects = JSON.parse(localStorage.getItem('projects') || '[]');
        const updatedProjects = localProjects.map(project => {
          if (project.id === projectId) {
            return {
              ...project,
              title: formData.title,
              description: formData.description,
              type: formData.type,
              technologies: formData.technologies.split(',').map(tech => tech.trim()),
              features: formData.features.split(',').map(feature => feature.trim()),
              link: formData.link || null,
              image: imageUrl,
              updatedAt: new Date().toISOString()
            };
          }
          return project;
        });
        localStorage.setItem('projects', JSON.stringify(updatedProjects));
      }

      try {
        // Upload new image if selected
        if (formData.image) {
          const imageRef = ref(storage, `projects/${formData.image.name}`);
          await uploadBytes(imageRef, formData.image);
          imageUrl = await getDownloadURL(imageRef);
        }

        // Update project document in Firestore
        const projectData = {
          title: formData.title,
          description: formData.description,
          type: formData.type,
          technologies: formData.technologies.split(',').map(tech => tech.trim()),
          features: formData.features.split(',').map(feature => feature.trim()),
          link: formData.link || null,
          image: imageUrl,
          updatedAt: new Date()
        };

        await updateDoc(doc(db, 'projects', projectId), projectData);
      } catch (err) {
        console.error('Firestore/Storage error:', err);
        if (process.env.NODE_ENV === 'development') {
          // En dev, continuer même si Firestore échoue
          navigate('/projects');
          return;
        }
        throw err;
      }

      navigate('/projects');
    } catch (error) {
      console.error('Error updating project:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.projects.edit.title')}
            </span>
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.title')}
              </label>
              <input
                type="text"
                required
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.description')}
              </label>
              <textarea
                required
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                rows="4"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.type')}
              </label>
              <select
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="website">Website</option>
                <option value="data_architecture">Data Architecture</option>
                <option value="visualization">Visualization</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.technologies')}
              </label>
              <input
                type="text"
                required
                value={formData.technologies}
                onChange={(e) => setFormData(prev => ({ ...prev, technologies: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                placeholder="React, Firebase, Tailwind CSS..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.features')}
              </label>
              <input
                type="text"
                required
                value={formData.features}
                onChange={(e) => setFormData(prev => ({ ...prev, features: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                placeholder="Feature 1, Feature 2, Feature 3..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.link')}
              </label>
              <input
                type="url"
                value={formData.link}
                onChange={(e) => setFormData(prev => ({ ...prev, link: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.image')}
              </label>
              {formData.currentImageUrl && (
                <div className="mb-2">
                  <img
                    src={formData.currentImageUrl}
                    alt={formData.title}
                    className="w-32 h-32 object-cover rounded-lg"
                  />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              />
              <p className="text-sm text-gray-500 mt-1">
                {t('pages.projects.edit.image_help')}
              </p>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/projects')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.projects.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {loading ? t('pages.projects.common.saving') : t('pages.projects.common.save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { CalendarIcon, ClockIcon, TagIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useAdmin } from '../hooks/useAdmin';
import TranslationWarning from '../components/TranslationWarning';
import 'react-quill/dist/quill.snow.css';

export default function BlogPost() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // En mode développement, essayer d'abord le localStorage
        if (process.env.NODE_ENV === 'development') {
          const posts = JSON.parse(localStorage.getItem('posts') || '[]');
          const localPost = posts.find(p => p.id === id);
          if (localPost) {
            setPost(localPost);
            setLoading(false);
            return;
          }
        }

        // Si pas trouvé dans le localStorage ou en production, utiliser Firestore
        const docRef = doc(db, 'posts', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setPost({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError(t('pages.blog.post_not_found'));
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(t('pages.blog.fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, t]);

  const isTranslationMissing = post && i18n.language === 'en' && (!post.titleEn || !post.contentEn);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.blog.loading')}
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center">
            <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>
            <button
              onClick={() => navigate('/blog')}
              className="text-primary hover:text-primary/80 font-medium transition-colors"
            >
              ← {t('pages.blog.back_to_blog')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  const displayTitle = i18n.language === 'en' && post.titleEn ? post.titleEn : post.title;
  const displayContent = i18n.language === 'en' && post.contentEn ? post.contentEn : post.content;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12 max-w-4xl">
        {/* Hero Image */}
        <div className="relative h-[500px] rounded-2xl overflow-hidden mb-8 shadow-xl">
          <img
            src={post.image}
            alt={displayTitle}
            className="w-full h-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-8">
            <div className="flex items-center space-x-4 text-white/80 text-sm mb-4">
              <span className="flex items-center">
                <CalendarIcon className="w-5 h-5 mr-2" />
                {new Date(post.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
              <span className="flex items-center">
                <ClockIcon className="w-5 h-5 mr-2" />
                {post.readTime}
              </span>
              <span className="flex items-center">
                <TagIcon className="w-5 h-5 mr-2" />
                {t(`pages.blog.categories.${post.category}`)}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <h1 className="text-4xl md:text-5xl font-bold text-white">
                {displayTitle}
              </h1>
              {isAdmin && (
                <button
                  onClick={() => navigate(`/blog/edit/${id}`)}
                  className="flex items-center space-x-2 bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  <PencilSquareIcon className="w-5 h-5" />
                  <span>{t('pages.blog.edit.button')}</span>
                </button>
              )}
            </div>
            {isTranslationMissing && <TranslationWarning />}
          </div>
        </div>

        {/* Content */}
        <article className="bg-white dark:bg-gray-800 rounded-2xl p-8 md:p-12 shadow-lg">
          <div 
            className="prose dark:prose-invert max-w-none
              prose-headings:text-gray-900 dark:prose-headings:text-white
              prose-p:text-gray-700 dark:prose-p:text-gray-300
              prose-a:text-primary hover:prose-a:text-primary/80
              prose-strong:text-gray-900 dark:prose-strong:text-white
              prose-ul:text-gray-700 dark:prose-ul:text-gray-300
              prose-ol:text-gray-700 dark:prose-ol:text-gray-300
              prose-li:text-gray-700 dark:prose-li:text-gray-300
              prose-blockquote:text-gray-700 dark:prose-blockquote:text-gray-300
              prose-code:text-gray-900 dark:prose-code:text-white
              prose-pre:bg-gray-100 dark:prose-pre:bg-gray-700
              prose-img:rounded-lg prose-img:shadow-lg
              [&_.ql-size-small]:text-sm
              [&_.ql-size-large]:text-xl
              [&_.ql-size-huge]:text-2xl
              [&_.ql-align-center]:text-center
              [&_.ql-align-right]:text-right
              [&_.ql-align-justify]:text-justify
              [&_.ql-font-serif]:font-serif
              [&_.ql-font-monospace]:font-mono
              [&_p]:text-gray-700 dark:[&_p]:text-gray-300
              [&_span]:text-gray-700 dark:[&_span]:text-gray-300
              [&_strong]:text-gray-900 dark:[&_strong]:text-white
              [&_em]:italic
              [&_u]:underline
              [&_s]:line-through
              [&_blockquote]:border-l-4 [&_blockquote]:border-gray-300 dark:[&_blockquote]:border-gray-600 [&_blockquote]:pl-4 [&_blockquote]:italic
              [&_code]:bg-gray-100 dark:[&_code]:bg-gray-700 [&_code]:rounded [&_code]:px-1"
            dangerouslySetInnerHTML={{ __html: displayContent }}
          />
        </article>

        {/* Back Button */}
        <div className="mt-8 text-center">
          <button
            onClick={() => navigate('/blog')}
            className="inline-flex items-center text-primary hover:text-primary/80 font-medium transition-colors"
          >
            ← {t('pages.blog.back_to_blog')}
          </button>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import { useBookUpload } from '../hooks/useBookUpload';
import BookForm from '../components/books/BookForm';

export default function CreateBook() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { 
    uploadCover, 
    uploadPdf, 
    uploadProgress, 
    error: uploadError,
    resetProgress 
  } = useBookUpload();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'my_book',
    cover: null,
    year: new Date().getFullYear().toString(),
    author: '',
    rating: 5,
    category: 'ai',
    previewPdf: null,
    readingTime: '15'
  });

  useEffect(() => {
    if (!isAdmin) {
      navigate('/books');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
  }, [uploadError]);

  const handleCoverChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.startsWith('image/')) {
        setError(t('pages.books.create.invalid_image'));
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError(t('pages.books.create.image_too_large'));
        return;
      }
      setFormData(prev => ({ ...prev, cover: file }));
      setError(null);
    }
  };

  const handlePdfChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type !== 'application/pdf') {
        setError(t('pages.books.create.invalid_pdf'));
        return;
      }
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        setError(t('pages.books.create.pdf_too_large'));
        return;
      }
      setFormData(prev => ({ ...prev, previewPdf: file }));
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      setError(t('pages.books.create.title_required'));
      return;
    }
    if (!formData.description.trim()) {
      setError(t('pages.books.create.description_required'));
      return;
    }
    if (!formData.cover) {
      setError(t('pages.books.create.cover_required'));
      return;
    }

    setSubmitting(true);
    setError(null);
    resetProgress();

    try {
      // Upload cover image
      let coverUrl = '';
      try {
        coverUrl = await uploadCover(formData.cover);
      } catch (err) {
        console.error('Cover upload error:', err);
        setError(t('pages.books.create.cover_upload_error'));
        setSubmitting(false);
        return;
      }

      // Upload PDF if provided (required for my_book type)
      let pdfUrl = '';
      if (formData.type === 'my_book') {
        if (!formData.previewPdf) {
          setError(t('pages.books.create.pdf_required'));
          setSubmitting(false);
          return;
        }
        try {
          pdfUrl = await uploadPdf(formData.previewPdf);
        } catch (err) {
          console.error('PDF upload error:', err);
          setError(t('pages.books.create.pdf_upload_error'));
          setSubmitting(false);
          return;
        }
      }

      const bookData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
        type: formData.type,
        cover: coverUrl,
        year: formData.year,
        author: formData.author.trim(),
        rating: parseInt(formData.rating),
        category: formData.category,
        previewPdf: pdfUrl,
        readingTime: parseInt(formData.readingTime),
        createdAt: new Date(),
        updatedAt: new Date()
      };

      try {
        // En mode développement, sauvegarder dans le localStorage
        if (process.env.NODE_ENV === 'development') {
          const existingBooks = JSON.parse(localStorage.getItem('books') || '[]');
          const newBook = { 
            id: Date.now().toString(), 
            ...bookData,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          existingBooks.push(newBook);
          localStorage.setItem('books', JSON.stringify(existingBooks));
        }

        // Sauvegarder dans Firestore
        await addDoc(collection(db, 'books'), bookData);
        navigate('/books');
      } catch (err) {
        console.error('Firestore error:', err);
        if (process.env.NODE_ENV === 'development') {
          navigate('/books');
          return;
        }
        throw err;
      }
    } catch (error) {
      console.error('Error creating book:', error);
      setError(t('pages.books.create.error'));
    } finally {
      setSubmitting(false);
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.books.create.title')}
            </span>
          </h1>

          {error && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <BookForm
              formData={formData}
              setFormData={setFormData}
              uploadProgress={uploadProgress}
              handleCoverChange={handleCoverChange}
              handlePdfChange={handlePdfChange}
              error={error}
            />

            <div className="mt-6 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/books')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.books.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {submitting ? t('pages.books.create.submitting') : t('pages.books.create.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange, placeholder }) => {
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      
      // Use MutationObserver instead of DOMNodeInserted
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' || mutation.type === 'characterData') {
            // Handle any necessary updates
            editor.update();
          }
        });
      });

      // Start observing the editor's root element
      observer.observe(editor.root, {
        childList: true,
        characterData: true,
        subtree: true
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'font', 'size',
    'list', 'bullet',
    'align',
    'link', 'image', 'video'
  ];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      className="[&_.ql-editor]:min-h-[250px] [&_.ql-container]:rounded-b-lg [&_.ql-toolbar]:rounded-t-lg 
                 [&_.ql-toolbar]:bg-white dark:[&_.ql-toolbar]:bg-gray-700 
                 [&_.ql-container]:bg-white dark:[&_.ql-container]:bg-gray-700 
                 [&_.ql-toolbar]:border-gray-300 dark:[&_.ql-toolbar]:border-gray-600 
                 [&_.ql-container]:border-gray-300 dark:[&_.ql-container]:border-gray-600 
                 [&_.ql-toolbar]:border-b-gray-300 dark:[&_.ql-toolbar]:border-b-gray-600
                 [&_.ql-editor]:text-gray-900 dark:[&_.ql-editor]:text-white"
    />
  );
};

export default RichTextEditor;

import { useState } from 'react';
import { auth } from '../firebase/config';

export const useMultiImageUpload = (folder = 'albums') => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [error, setError] = useState(null);

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          
          // Calculate new dimensions while maintaining aspect ratio
          const maxDimension = 1920; // Max width or height
          if (width > height && width > maxDimension) {
            height = (height * maxDimension) / width;
            width = maxDimension;
          } else if (height > maxDimension) {
            width = (width * maxDimension) / height;
            height = maxDimension;
          }
          
          canvas.width = width;
          canvas.height = height;
          
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to base64 with reduced quality
          const quality = 0.8; // 80% quality
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            quality
          );
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadImage = async (file, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        setError(null);
        setUploadProgress(prev => ({ ...prev, [index]: 10 }));
        
        // Get the current user's ID token
        const token = await auth.currentUser.getIdToken();

        // Compress image before upload
        const compressedBlob = await compressImage(file);
        setUploadProgress(prev => ({ ...prev, [index]: 20 }));

        // Convert compressed blob to base64
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const base64data = reader.result;
            
            // Call the Cloud Function
            const response = await fetch('https://us-central1-kohnnect-prod-2024.cloudfunctions.net/uploadImage', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                file: base64data,
                contentType: file.type,
                folder: `${folder}/${Date.now()}`
              })
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.error || 'Upload failed');
            }

            setUploadProgress(prev => ({ ...prev, [index]: 90 }));
            const data = await response.json();
            setUploadProgress(prev => ({ ...prev, [index]: 100 }));
            resolve(data.url);
          } catch (error) {
            console.error('Upload error:', error);
            setError(error.message);
            reject(error);
          }
        };

        reader.onerror = (error) => {
          console.error('File reading error:', error);
          setError('Failed to read file');
          reject(error);
        };
        
        reader.readAsDataURL(compressedBlob);
      } catch (error) {
        console.error('Upload preparation error:', error);
        setError(error.message);
        reject(error);
      }
    });
  };

  const uploadMultipleImages = async (files) => {
    const uploadPromises = files.map((file, index) => uploadImage(file, index));
    return Promise.all(uploadPromises);
  };

  const getTotalProgress = () => {
    if (Object.keys(uploadProgress).length === 0) return 0;
    const total = Object.values(uploadProgress).reduce((acc, curr) => acc + curr, 0);
    return Math.round(total / Object.keys(uploadProgress).length);
  };

  const resetProgress = () => {
    setUploadProgress({});
    setError(null);
  };

  return {
    uploadMultipleImages,
    uploadProgress,
    totalProgress: getTotalProgress(),
    error,
    setError,
    resetProgress
  };
};

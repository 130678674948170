import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { XMarkIcon, StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';

const StarRating = ({ rating }) => (
  <div className="flex gap-1">
    {[1, 2, 3, 4, 5].map((star) => (
      star <= rating ? (
        <StarIconSolid key={star} className="w-5 h-5 text-yellow-400" />
      ) : (
        <StarIcon key={star} className="w-5 h-5 text-gray-300 dark:text-gray-600" />
      )
    ))}
  </div>
);

const TechModal = ({ tech, onClose }) => {
  const { t } = useTranslation();
  const type = tech.type || 'tech';
  const techKey = `pages.about.${type}.${tech.key}`;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white dark:bg-gray-800 rounded-2xl p-6 max-w-lg w-full mx-4 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-xl bg-white p-2 shadow-lg">
              <img src={tech.logo} alt={t(`${techKey}.name`)} className="w-full h-full object-contain" />
            </div>
            <div>
              <h3 className="text-2xl font-bold">{t(`${techKey}.name`)}</h3>
              <StarRating rating={tech.rating} />
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          {t(`${techKey}.description`)}
        </p>

        <div className="space-y-4">
          <h4 className="font-semibold text-primary">{t('pages.about.tech.achievements_title')}</h4>
          <ul className="space-y-2">
            {t(`${techKey}.projects`, { returnObjects: true }).map((project, index) => (
              <li key={index} className="flex items-start gap-2 text-gray-600 dark:text-gray-300">
                <span className="w-1.5 h-1.5 mt-2 bg-primary rounded-full flex-shrink-0" />
                <span>{project}</span>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TechModal;

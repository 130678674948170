import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import { useMultiImageUpload } from '../hooks/useMultiImageUpload';

export default function CreateAlbum() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { 
    uploadMultipleImages, 
    totalProgress, 
    error: uploadError, 
    resetProgress 
  } = useMultiImageUpload('albums');

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    photos: []
  });

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin) {
      navigate('/photography');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
  }, [uploadError]);

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    
    const validFiles = files.filter(file => {
      if (!file.type.startsWith('image/')) {
        setError(t('pages.photography.create.invalid_image'));
        return false;
      }
      if (file.size > 50 * 1024 * 1024) { // 50MB limit
        setError(t('pages.photography.create.image_too_large'));
        return false;
      }
      return true;
    });

    setFormData(prev => ({
      ...prev,
      photos: [...prev.photos, ...validFiles]
    }));
    setError(null);
  };

  const removePhoto = (index) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!formData.title.trim()) {
        setError(t('pages.photography.create.title_required'));
        return;
      }
      if (!formData.description.trim()) {
        setError(t('pages.photography.create.description_required'));
        return;
      }
      if (formData.photos.length === 0) {
        setError(t('pages.photography.create.photos_required'));
        return;
      }

      setSubmitting(true);
      setError(null);
      resetProgress();

      // Upload all photos
      const uploadedUrls = await uploadMultipleImages(formData.photos);

      // Create album document with uploaded photo URLs
      const albumData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
        date: formData.date,
        createdAt: new Date(),
        updatedAt: new Date(),
        photos: uploadedUrls.map(url => ({
          id: Date.now().toString(),
          url,
          caption: ''
        }))
      };

      await addDoc(collection(db, 'albums'), albumData);
      navigate('/photography');
    } catch (error) {
      console.error('Error creating album:', error);
      setError(t('pages.photography.create.error'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.photography.create.title')}
            </span>
          </h1>

          {error && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error}
            </div>
          )}

          {totalProgress > 0 && totalProgress < 100 && (
            <div className="mb-6">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-primary h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${totalProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                {t('pages.photography.create.uploading')}: {totalProgress}%
              </p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.create.title_label')}
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.create.description_label')}
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.create.date_label')}
              </label>
              <input
                type="date"
                value={formData.date}
                onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Photos Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.create.photos_label')}
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handlePhotoUpload}
                className="w-full text-sm text-gray-500 dark:text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-primary file:text-white
                  hover:file:bg-primary/80
                  cursor-pointer"
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Maximum file size: 50MB
              </p>
            </div>

            {/* Photos Preview */}
            {formData.photos.length > 0 && (
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {formData.photos.map((photo, index) => (
                  <div key={index} className="relative group">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt={`Upload ${index + 1}`}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => removePhoto(index)}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/photography')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.photography.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {submitting ? t('pages.photography.create.submitting') : t('pages.photography.create.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useRef } from 'react';

export default function ParticleBackground() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];
    let lastTime = 0;
    const FPS = 60;
    const frameDelay = 1000 / FPS;
    let isScrolling = false;
    let scrollTimeout;

    // Set canvas size
    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(dpr, dpr);
    };

    class Particle {
      constructor() {
        this.reset();
      }

      reset() {
        const rect = canvas.getBoundingClientRect();
        this.x = Math.random() * rect.width;
        this.y = Math.random() * rect.height;
        this.size = Math.random() * 2 + 0.5;
        this.baseSpeedX = Math.random() * 0.8 - 0.4;
        this.baseSpeedY = Math.random() * 0.8 - 0.4;
        this.speedX = this.baseSpeedX;
        this.speedY = this.baseSpeedY;
        
        const colors = [
          { r: 66, g: 133, b: 244, a: 0.6 },    // Primary blue
          { r: 94, g: 114, b: 228, a: 0.5 },    // Accent blue
        ];
        
        this.color = colors[Math.floor(Math.random() * colors.length)];
      }

      update(isScrolling) {
        const rect = canvas.getBoundingClientRect();
        
        // Adjust speed during scrolling
        if (isScrolling) {
          this.speedX = this.baseSpeedX * 0.5;
          this.speedY = this.baseSpeedY * 0.5;
        } else {
          this.speedX = this.baseSpeedX;
          this.speedY = this.baseSpeedY;
        }

        this.x += this.speedX;
        this.y += this.speedY;

        // Improved wrapping logic
        if (this.x > rect.width + 50) this.x = -50;
        if (this.x < -50) this.x = rect.width + 50;
        if (this.y > rect.height + 50) this.y = -50;
        if (this.y < -50) this.y = rect.height + 50;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${this.color.a})`;
        ctx.fill();
      }

      getGradientColor(alpha) {
        return `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${alpha})`;
      }
    }

    const init = () => {
      const rect = canvas.getBoundingClientRect();
      particles = [];
      const isMobile = window.innerWidth <= 768;
      const baseParticleCount = isMobile ? 120 : 200; // Increased particle count
      const numberOfParticles = Math.min((rect.width * rect.height) / 4000, baseParticleCount);
      
      for (let i = 0; i < numberOfParticles; i++) {
        particles.push(new Particle());
      }
    };

    const animate = (currentTime) => {
      if (!document.hidden && currentTime - lastTime >= frameDelay) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        particles.forEach(particle => {
          particle.update(isScrolling);
          particle.draw();
        });

        // Only draw connections when not scrolling to improve performance
        if (!isScrolling) {
          const maxDistance = 120;
          const maxDistanceSquared = maxDistance * maxDistance;

          for (let i = 0; i < particles.length; i++) {
            for (let j = i + 1; j < particles.length; j++) {
              const dx = particles[i].x - particles[j].x;
              const dy = particles[i].y - particles[j].y;
              const distanceSquared = dx * dx + dy * dy;

              if (distanceSquared < maxDistanceSquared) {
                const alpha = (1 - Math.sqrt(distanceSquared) / maxDistance) * 0.5;
                const gradient = ctx.createLinearGradient(
                  particles[i].x,
                  particles[i].y,
                  particles[j].x,
                  particles[j].y
                );
                gradient.addColorStop(0, particles[i].getGradientColor(alpha));
                gradient.addColorStop(1, particles[j].getGradientColor(alpha));

                ctx.beginPath();
                ctx.strokeStyle = gradient;
                ctx.lineWidth = 0.8;
                ctx.moveTo(particles[i].x, particles[i].y);
                ctx.lineTo(particles[j].x, particles[j].y);
                ctx.stroke();
              }
            }
          }
        }

        lastTime = currentTime;
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    // Handle scroll events
    const handleScroll = () => {
      isScrolling = true;
      clearTimeout(scrollTimeout);
      
      // Reset scrolling state after scrolling stops
      scrollTimeout = setTimeout(() => {
        isScrolling = false;
      }, 150);
    };

    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setCanvasSize();
        init();
      }, 250);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        cancelAnimationFrame(animationFrameId);
      } else {
        animate(performance.now());
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    setCanvasSize();
    init();
    animate(0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      cancelAnimationFrame(animationFrameId);
      clearTimeout(resizeTimeout);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 w-full h-full pointer-events-none"
      style={{ opacity: 0.8 }}
    />
  );
}

import premiereLogo from '../assets/images/AdobePremierePro.png';
import canvaLogo from '../assets/images/Canva.png';
import luminarLogo from '../assets/images/luminar_neo-icon-big.png';

export const creativeTools = {
  premiere: {
    name: 'Adobe Premiere Pro',
    logo: premiereLogo,
    rating: 4,
    description: 'Video editing and production',
    projects: [
      'Created promotional videos',
      'Edited conference recordings',
      'Produced social media content',
      'Developed video transitions'
    ]
  },
  canva: {
    name: 'Canva',
    logo: canvaLogo,
    rating: 4,
    description: 'Graphic design and visual content creation',
    projects: [
      'Designed social media graphics',
      'Created presentation templates',
      'Developed marketing materials',
      'Built brand assets'
    ]
  },
  luminar: {
    name: 'Luminar Neo',
    logo: luminarLogo,
    rating: 3,
    description: 'Advanced photo editing and enhancement',
    projects: [
      'Enhanced photography portfolio',
      'Applied artistic effects',
      'Performed photo retouching',
      'Created visual styles'
    ]
  }
};

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db, storage } from '../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAdmin } from '../hooks/useAdmin';

const categories = ['ai', 'psychology', 'algorithms', 'big_data', 'sociology', 'network_science'];

export default function EditBook() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/books');
      return;
    }

    const fetchBook = async () => {
      try {
        // En mode développement, chercher d'abord dans le localStorage
        if (process.env.NODE_ENV === 'development') {
          const localBooks = JSON.parse(localStorage.getItem('books') || '[]');
          const book = localBooks.find(b => b.id === id);
          if (book) {
            // Ensure category is set to a valid value
            if (!book.category || !categories.includes(book.category)) {
              book.category = categories[0];
            }
            setFormData(book);
            setLoading(false);
            return;
          }
        }

        // Si pas trouvé dans le localStorage ou en production, utiliser Firestore
        const bookDoc = await getDoc(doc(db, 'books', id));
        if (bookDoc.exists()) {
          const bookData = { id: bookDoc.id, ...bookDoc.data() };
          // Ensure category is set to a valid value
          if (!bookData.category || !categories.includes(bookData.category)) {
            bookData.category = categories[0];
          }
          setFormData(bookData);
        } else {
          setError(t('pages.books.edit.not_found'));
        }
      } catch (err) {
        console.error('Error fetching book:', err);
        setError(t('pages.books.edit.fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchBook();
  }, [id, isAdmin, navigate, t]);

  const handleCoverChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.startsWith('image/')) {
        setError(t('pages.books.edit.invalid_image'));
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError(t('pages.books.edit.image_too_large'));
        return;
      }

      // Convertir l'image en data URL pour le localStorage
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          cover: reader.result,
          coverFile: file // Garder le fichier pour l'upload Firebase
        }));
      };
      reader.readAsDataURL(file);
      setError(null);
    }
  };

  const handlePdfChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type !== 'application/pdf') {
        setError(t('pages.books.edit.invalid_pdf'));
        return;
      }
      if (file.size > 10 * 1024 * 1024) {
        setError(t('pages.books.edit.pdf_too_large'));
        return;
      }

      // Convertir le PDF en data URL pour le localStorage
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          previewPdf: reader.result,
          pdfFile: file // Garder le fichier pour l'upload Firebase
        }));
      };
      reader.readAsDataURL(file);
      setError(null);
    }
  };

const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title.trim()) {
      setError(t('pages.books.edit.title_required'));
      return;
    }
    if (!formData.description.trim()) {
      setError(t('pages.books.edit.description_required'));
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      const updatedData = {
        ...formData,
        title: formData.title.trim(),
        description: formData.description.trim(),
        readingTime: parseInt(formData.readingTime || '15'),
        updatedAt: new Date().toISOString()
      };

      // En mode développement, mettre à jour le localStorage
      if (process.env.NODE_ENV === 'development') {
        const localBooks = JSON.parse(localStorage.getItem('books') || '[]');
        const updatedBooks = localBooks.map(book => 
          book.id === id ? updatedData : book
        );
        localStorage.setItem('books', JSON.stringify(updatedBooks));
      }

      // En production ou si le localStorage n'est pas disponible
      try {
        let coverUrl = formData.cover;
        let pdfUrl = formData.previewPdf;

        // Upload new cover if changed
        if (formData.coverFile) {
          const coverRef = ref(storage, `books/covers/${Date.now()}_${formData.coverFile.name}`);
          const coverUpload = await uploadBytes(coverRef, formData.coverFile);
          coverUrl = await getDownloadURL(coverUpload.ref);
        }

        // Upload new PDF if changed
        if (formData.pdfFile) {
          const pdfRef = ref(storage, `books/pdfs/${Date.now()}_${formData.pdfFile.name}`);
          const pdfUpload = await uploadBytes(pdfRef, formData.pdfFile);
          pdfUrl = await getDownloadURL(pdfUpload.ref);
        }

        // Update Firestore with all fields including readingTime
        const bookData = {
          title: formData.title.trim(),
          description: formData.description.trim(),
          type: formData.type,
          cover: coverUrl,
          year: formData.year,
          author: formData.author?.trim(),
          rating: parseInt(formData.rating),
          category: formData.category,
          previewPdf: pdfUrl,
          readingTime: parseInt(formData.readingTime || '15'),
          updatedAt: new Date()
        };

        await updateDoc(doc(db, 'books', id), bookData);
      } catch (err) {
        console.error('Firestore/Storage error:', err);
        if (process.env.NODE_ENV === 'development') {
          // En dev, continuer même si Firestore échoue
          navigate('/books');
          return;
        }
        throw err;
      }

      navigate('/books');
    } catch (error) {
      console.error('Error updating book:', error);
      setError(t('pages.books.edit.error'));
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.books.loading')}
          </div>
        </div>
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-red-600 dark:text-red-400">
            {error || t('pages.books.edit.not_found')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.books.edit.title')}
            </span>
          </h1>

          {error && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Book Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.books.edit.type_label')}
              </label>
              <select
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="my_book">{t('pages.books.edit.type_my_book')}</option>
                <option value="recommended">{t('pages.books.edit.type_recommended')}</option>
              </select>
            </div>

            {/* Cover Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.books.edit.cover_label')}
              </label>
              {formData.cover && (
                <img
                  src={formData.cover}
                  alt="Current cover"
                  className="w-32 h-48 object-cover rounded-lg mb-4"
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleCoverChange}
                className="w-full text-sm text-gray-500 dark:text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-primary file:text-white
                  hover:file:bg-primary/80
                  cursor-pointer"
              />
            </div>

            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.books.edit.title_label')}
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.books.edit.description_label')}
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={5}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Year */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.books.edit.year_label')}
              </label>
              <input
                type="number"
                value={formData.year}
                onChange={(e) => setFormData(prev => ({ ...prev, year: e.target.value }))}
                min="1900"
                max={new Date().getFullYear()}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              />
            </div>

            {formData.type === 'recommended' && (
              <>
                {/* Author */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    {t('pages.books.edit.author_label')}
                  </label>
                  <input
                    type="text"
                    value={formData.author}
                    onChange={(e) => setFormData(prev => ({ ...prev, author: e.target.value }))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  />
                </div>

                {/* Reading Time */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Temps de lecture (minutes)
                  </label>
                  <input
                    type="number"
                    value={formData.readingTime || '15'}
                    onChange={(e) => setFormData(prev => ({ ...prev, readingTime: e.target.value }))}
                    min="1"
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    required
                  />
                </div>

                {/* Category */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    {t('pages.books.edit.category_label')}
                  </label>
                  <select
                    value={formData.category}
                    onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  >
                    {categories.map(category => (
                      <option key={category} value={category}>
                        {t(`pages.books.categories.${category}`)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {formData.type === 'my_book' && (
              /* PDF Upload */
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  {t('pages.books.edit.pdf_label')}
                </label>
                {formData.previewPdf && (
                  <div className="mb-4">
                    <a
                      href={formData.previewPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary/80"
                    >
                      {t('pages.books.edit.current_pdf')}
                    </a>
                  </div>
                )}
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handlePdfChange}
                  className="w-full text-sm text-gray-500 dark:text-gray-400
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-primary file:text-white
                    hover:file:bg-primary/80
                    cursor-pointer"
                />
              </div>
            )}

            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/books')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.books.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {submitting ? t('pages.books.edit.submitting') : t('pages.books.edit.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

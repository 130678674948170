import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import ProjectCard from '../components/projects/ProjectCard';
import { useAdmin } from '../hooks/useAdmin';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function Projects() {
  const { t } = useTranslation();
  const isAdmin = useAdmin();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setError(null);
        console.log('Environment:', process.env.NODE_ENV);
        
        // En mode développement, essayer d'abord le localStorage
        if (process.env.NODE_ENV === 'development') {
          const localProjects = JSON.parse(localStorage.getItem('projects') || '[]');
          if (localProjects.length > 0) {
            console.log('Using localStorage projects:', localProjects);
            setProjects(localProjects);
            setLoading(false);
            return;
          }
        }

        // Si pas de données dans le localStorage ou en production, utiliser Firestore
        console.log('Fetching from Firestore...');
        const projectsRef = collection(db, 'projects');
        console.log('Collection reference:', projectsRef);
        
        const q = query(projectsRef, orderBy('createdAt', 'desc'));
        console.log('Query:', q);
        
        const querySnapshot = await getDocs(q);
        console.log('Query snapshot:', querySnapshot);
        console.log('Number of documents:', querySnapshot.size);
        
        const projectsData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Document ID:', doc.id);
          console.log('Raw document data:', data);
          
          // Ensure all required fields exist
          const processedData = {
            id: doc.id,
            title: data.title || 'Untitled Project',
            description: data.description || 'No description available',
            features: Array.isArray(data.features) ? data.features : [],
            technologies: Array.isArray(data.technologies) ? data.technologies : [],
            type: data.type || 'other',
            image: data.image || 'https://via.placeholder.com/400x300',
            createdAt: data.createdAt || null,
            link: data.link || '',
            titleEn: data.titleEn || '',
            descriptionEn: data.descriptionEn || '',
            featuresEn: Array.isArray(data.featuresEn) ? data.featuresEn : []
          };
          
          console.log('Processed document data:', processedData);
          return processedData;
        });

        console.log('Final projects data:', projectsData);
        setProjects(projectsData);
      } catch (error) {
        console.error('Error details:', error);
        setError(error.message);
        setProjects([]); // Set empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleDelete = (projectId) => {
    // En mode développement, supprimer aussi du localStorage
    if (process.env.NODE_ENV === 'development') {
      const localProjects = JSON.parse(localStorage.getItem('projects') || '[]');
      const updatedProjects = localProjects.filter(project => project.id !== projectId);
      localStorage.setItem('projects', JSON.stringify(updatedProjects));
    }
    setProjects(prev => prev.filter(project => project.id !== projectId));
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="text-center mb-12">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-4xl md:text-5xl font-bold">
              <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
                {t('pages.projects.header.title')}
              </span>
            </h1>
            {isAdmin && (
              <Link
                to="/projects/create"
                className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
              >
                <PlusIcon className="w-5 h-5 mr-2" />
                {t('pages.projects.create_button')}
              </Link>
            )}
          </div>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('pages.projects.header.subtitle')}
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
        ) : error ? (
          <div className="text-center text-red-500">
            <p>Error loading projects: {error}</p>
          </div>
        ) : projects.length === 0 ? (
          <div className="text-center text-gray-600 dark:text-gray-300">
            <p>No projects found.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {projects.map(project => (
              <ProjectCard 
                key={project.id} 
                project={project}
                onDelete={handleDelete}
              />
            ))}
          </div>
        )}

        {/* Call to Action */}
        <div className="mt-20 text-center">
          <div className="max-w-2xl mx-auto p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
            <h2 className="text-2xl font-bold mb-4">
              <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
                {t('pages.projects.cta.title')}
              </span>
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              {t('pages.projects.cta.subtitle')}
            </p>
            <a
              href="mailto:contact@example.com"
              className="inline-block px-6 py-3 bg-primary text-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              {t('pages.projects.cta.button')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

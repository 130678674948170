import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAdmin } from '../../hooks/useAdmin';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import {
  GlobeAltIcon,
  ServerIcon,
  ChartBarIcon,
  CodeBracketIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline';
import TranslationWarning from '../TranslationWarning';

const ProjectCard = ({ project, onDelete }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [imageError, setImageError] = useState(false);
  
  // Ensure project has all required fields with defaults
  const safeProject = {
    id: project?.id || 'unknown',
    title: project?.title || 'Untitled Project',
    description: project?.description || 'No description available',
    features: Array.isArray(project?.features) ? project.features : [],
    technologies: Array.isArray(project?.technologies) ? project.technologies : [],
    type: project?.type || 'other',
    image: project?.image || 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjZjBmMGYwIi8+PHRleHQgeD0iNTAlIiB5PSI1MCUiIGZvbnQtZmFtaWx5PSJBcmlhbCIgZm9udC1zaXplPSIyNCIgZmlsbD0iIzY2NiIgdGV4dC1hbmNob3I9Im1pZGRsZSIgZHk9Ii4zZW0iPk5vIEltYWdlPC90ZXh0Pjwvc3ZnPg==',
    link: project?.link || '',
    titleEn: project?.titleEn || '',
    descriptionEn: project?.descriptionEn || '',
    featuresEn: Array.isArray(project?.featuresEn) ? project.featuresEn : []
  };
  
  const getIcon = (type) => {
    switch (type) {
      case 'website':
        return GlobeAltIcon;
      case 'data_architecture':
        return ServerIcon;
      case 'visualization':
        return ChartBarIcon;
      default:
        return CodeBracketIcon;
    }
  };

  const handleDelete = async () => {
    if (window.confirm(t('pages.projects.delete_confirm'))) {
      try {
        await deleteDoc(doc(db, 'projects', safeProject.id));
        onDelete(safeProject.id);
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
  };

  const handleEdit = () => {
    navigate(`/projects/edit/${safeProject.id}`);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const Icon = getIcon(safeProject.type);

  const isTranslationMissing = i18n.language === 'en' && (
    !safeProject.titleEn || 
    !safeProject.descriptionEn || 
    !safeProject.featuresEn
  );

  const displayTitle = i18n.language === 'en' && safeProject.titleEn ? safeProject.titleEn : safeProject.title;
  const displayDescription = i18n.language === 'en' && safeProject.descriptionEn ? safeProject.descriptionEn : safeProject.description;
  const displayFeatures = i18n.language === 'en' && safeProject.featuresEn ? safeProject.featuresEn : safeProject.features;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300">
      <div className="relative aspect-video overflow-hidden bg-gray-100 dark:bg-gray-700">
        <img
          src={imageError ? safeProject.image : project?.image || safeProject.image}
          alt={displayTitle}
          className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
          onError={handleImageError}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
          <div className="absolute bottom-4 left-4">
            <Icon className="w-8 h-8 text-white" />
          </div>
          {isAdmin && (
            <div className="absolute top-4 right-4 flex space-x-2">
              <button
                onClick={handleEdit}
                className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
                title={t('pages.projects.common.edit')}
              >
                <PencilSquareIcon className="w-5 h-5 text-white" />
              </button>
              <button
                onClick={handleDelete}
                className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                title={t('pages.projects.common.delete')}
              >
                <TrashIcon className="w-5 h-5 text-white" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-2xl font-bold">{displayTitle}</h3>
            {isTranslationMissing && <TranslationWarning />}
          </div>
          {safeProject.link && (
            <a
              href={safeProject.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-primary/80 transition-colors"
            >
              <ArrowTopRightOnSquareIcon className="w-6 h-6" />
            </a>
          )}
        </div>

        <p className="text-gray-600 dark:text-gray-300 mb-6">
          {displayDescription}
        </p>

        <div className="space-y-6">
          {/* Technologies */}
          {safeProject.technologies.length > 0 && (
            <div>
              <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                {t('pages.projects.labels.technologies')}
              </h4>
              <div className="flex flex-wrap gap-2">
                {safeProject.technologies.map((tech, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Features */}
          {displayFeatures.length > 0 && (
            <div>
              <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
                {t('pages.projects.labels.key_features')}
              </h4>
              <ul className="grid grid-cols-2 gap-2">
                {displayFeatures.map((feature, index) => (
                  <li
                    key={index}
                    className="flex items-center text-gray-600 dark:text-gray-300 text-sm"
                  >
                    <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { 
  BeakerIcon, 
  CodeBracketIcon,
  CpuChipIcon, 
  ChartBarIcon,
  UserGroupIcon,
  HashtagIcon,
  TrashIcon,
  PlusIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline';
import { db } from '../firebase/config';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import TranslationWarning from '../components/TranslationWarning';

// Helper function to generate Firestore-like IDs (same as in CreateBlogPost)
const generateFirestoreId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 20; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

// Function to strip HTML tags and get plain text
const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

// Function to migrate old timestamp-based IDs to Firestore-like IDs
const migrateLocalStoragePosts = () => {
  const posts = JSON.parse(localStorage.getItem('posts') || '[]');
  const needsMigration = posts.some(post => !isNaN(post.id));
  
  if (needsMigration) {
    const migratedPosts = posts.map(post => ({
      ...post,
      id: isNaN(post.id) ? post.id : generateFirestoreId()
    }));
    localStorage.setItem('posts', JSON.stringify(migratedPosts));
    return migratedPosts;
  }
  
  return posts;
};

const BlogCard = ({ post, onDelete, isAdmin }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm(t('pages.blog.delete_confirm'))) {
      await onDelete(post.id);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/blog/edit/${post.id}`);
  };

  const handleClick = () => {
    navigate(`/blog/${post.id}`);
  };

  // Ensure post has all required fields with defaults
  const safePost = {
    title: post.title || 'Untitled Post',
    content: post.content || '',
    category: post.category || 'other',
    date: post.date || new Date().toISOString(),
    image: post.image || '/placeholder.jpg',
    readTime: post.readTime || '5 min',
    titleEn: post.titleEn || '',
    contentEn: post.contentEn || '',
    ...post
  };

  const isTranslationMissing = i18n.language === 'en' && (!safePost.titleEn || !safePost.contentEn);
  const displayTitle = i18n.language === 'en' && safePost.titleEn ? safePost.titleEn : safePost.title;
  const displayContent = i18n.language === 'en' && safePost.contentEn ? safePost.contentEn : safePost.content;
  
  return (
    <article 
      onClick={handleClick}
      className="group relative bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer"
    >
      <div className="aspect-w-16 aspect-h-9 overflow-hidden h-96">
        <img 
          src={safePost.image} 
          alt={displayTitle}
          className="w-full h-full object-cover object-center transform group-hover:scale-105 transition-transform duration-300"
        />
        {isAdmin && (
          <div className="absolute top-2 right-2 flex space-x-2">
            <button
              onClick={handleEdit}
              className="p-2 bg-primary hover:bg-primary/80 text-white rounded-full shadow-lg transition-colors"
              title={t('pages.blog.edit.button')}
            >
              <PencilSquareIcon className="w-5 h-5" />
            </button>
            <button
              onClick={handleDelete}
              className="p-2 bg-red-500 hover:bg-red-600 text-white rounded-full shadow-lg transition-colors"
              title={t('pages.blog.delete')}
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        )}
      </div>
      <div className="p-6">
        <div className="flex items-center space-x-2 mb-4">
          <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm font-medium">
            {t(`pages.blog.categories.${safePost.category.toLowerCase()}`)}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {safePost.readTime}
          </span>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 group-hover:text-primary transition-colors">
            {displayTitle}
          </h3>
          {isTranslationMissing && <TranslationWarning />}
        </div>
        <p className="text-gray-600 dark:text-gray-300 mb-4 text-lg">
          {stripHtml(displayContent).substring(0, 150)}...
        </p>
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {new Date(safePost.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </span>
          <span className="text-primary hover:text-primary/80 font-medium transition-colors">
            {t('pages.blog.read_more')} →
          </span>
        </div>
      </div>
    </article>
  );
};

export default function Blog() {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isAdmin = useAdmin();

  const categories = [
    { name: 'all', icon: HashtagIcon },
    { name: 'ai', icon: CpuChipIcon },
    { name: 'data_engineer', icon: BeakerIcon },
    { name: 'algorithms', icon: CodeBracketIcon },
    { name: 'big_data', icon: ChartBarIcon },
    { name: 'sociology', icon: UserGroupIcon },
  ];

  const setFetchError = useCallback((error) => {
    setError(t('pages.blog.fetch_error'));
  }, [t]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (process.env.NODE_ENV === 'development') {
          // Migrate any old posts to new ID format
          const migratedPosts = migrateLocalStoragePosts();
          setPosts(migratedPosts);
          setLoading(false);
          return;
        }

        const querySnapshot = await getDocs(collection(db, 'posts'));
        const fetchedPosts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // Ensure required fields exist
          category: doc.data().category || 'other',
          title: doc.data().title || 'Untitled Post',
          content: doc.data().content || '',
          date: doc.data().date || new Date().toISOString(),
          image: doc.data().image || '/placeholder.jpg',
          readTime: doc.data().readTime || '5 min'
        }));
        setPosts(fetchedPosts);
        setError(null);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setFetchError(err);
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [setFetchError]);

  const handleDelete = async (postId) => {
    try {
      if (process.env.NODE_ENV === 'development') {
        const localPosts = JSON.parse(localStorage.getItem('posts') || '[]');
        const updatedPosts = localPosts.filter(post => post.id !== postId);
        localStorage.setItem('posts', JSON.stringify(updatedPosts));
        setPosts(updatedPosts);
        return;
      }

      await deleteDoc(doc(db, 'posts', postId));
      setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
      alert(t('pages.blog.delete_error'));
    }
  };

  const filteredPosts = selectedCategory === 'all'
    ? posts
    : posts.filter(post => (post.category || 'other').toLowerCase() === selectedCategory);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              Blog
            </span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('pages.blog.subtitle')}
          </p>
          
          {error && (
            <p className="mt-4 text-amber-600 dark:text-amber-400">
              {error}
            </p>
          )}
          
          {/* Create Post Button for Admins */}
          {isAdmin && (
            <Link
              to="/blog/create"
              className="inline-flex items-center mt-6 px-6 py-3 bg-primary hover:bg-primary/80 text-white font-bold rounded-lg transition-colors"
            >
              <PlusIcon className="w-5 h-5 mr-2" />
              {t('pages.blog.create_post')}
            </Link>
          )}
        </div>

        {/* Categories */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {categories.map(({ name, icon: Icon }) => (
            <button
              key={name}
              onClick={() => setSelectedCategory(name)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300 ${
                selectedCategory === name
                  ? 'bg-primary text-white shadow-lg'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:shadow-md'
              }`}
            >
              <Icon className="w-5 h-5" />
              <span>{t(`pages.blog.categories.${name}`)}</span>
            </button>
          ))}
        </div>

        {/* Posts Grid */}
        {loading ? (
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.blog.loading')}
          </div>
        ) : filteredPosts.length === 0 ? (
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.blog.no_posts')}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {filteredPosts.map(post => (
              <BlogCard 
                key={post.id} 
                post={post} 
                onDelete={handleDelete}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const SectionHeader = ({ icon: Icon, title }) => (
  <div className="flex items-center gap-3 mb-12">
    <div className="p-2 bg-primary/10 rounded-xl">
      <Icon className="w-6 h-6 text-primary" />
    </div>
    <h2 className="text-2xl font-bold">{title}</h2>
  </div>
);

export default SectionHeader;

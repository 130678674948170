import { useTranslation } from 'react-i18next';

export const DeleteConfirmModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 max-w-md mx-4">
        <h3 className="text-xl font-bold mb-4">
          {t('pages.books.delete_confirm.title')}
        </h3>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          {t('pages.books.delete_confirm.message')}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600"
          >
            {t('pages.books.common.cancel')}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            {t('pages.books.delete_confirm.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

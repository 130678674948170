import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const PassionCard = ({ passionKey, icon }) => {
  const { t } = useTranslation();
  
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg text-center"
    >
      <span className="text-4xl mb-4 block">{icon}</span>
      <h3 className="text-lg font-bold mb-2">{t(`pages.about.passions.${passionKey}.name`)}</h3>
      <p className="text-gray-600 dark:text-gray-300 text-sm">
        {t(`pages.about.passions.${passionKey}.description`)}
      </p>
    </motion.div>
  );
};

export default PassionCard;

import React from 'react';

const TranslationWarning = () => {
  return (
    <div className="text-amber-500 text-sm italic mt-1">
      (English translation not available)
    </div>
  );
};

export default TranslationWarning;

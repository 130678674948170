import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAdmin } from '../hooks/useAdmin';
import { useImageUpload } from '../hooks/useImageUpload';

export default function CreateProject() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { uploadImage, uploadProgress, error: uploadError } = useImageUpload('projects');

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'website',
    technologies: '',
    features: '',
    link: '',
    image: null
  });

  if (!isAdmin) {
    navigate('/projects');
    return null;
  }

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({ ...prev, image: e.target.files[0] }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Upload image to Storage
      let imageUrl = '';
      if (formData.image) {
        try {
          imageUrl = await uploadImage(formData.image);
        } catch (err) {
          console.error('Image upload error:', err);
          setError(t('pages.projects.create.error.image_upload'));
          setLoading(false);
          return;
        }
      }

      // Create project document
      const projectData = {
        title: formData.title,
        description: formData.description,
        type: formData.type,
        technologies: formData.technologies.split(',').map(tech => tech.trim()),
        features: formData.features.split(',').map(feature => feature.trim()),
        link: formData.link || null,
        image: imageUrl,
        createdAt: new Date()
      };

      try {
        // En mode développement, sauvegarder dans le localStorage
        if (process.env.NODE_ENV === 'development') {
          const existingProjects = JSON.parse(localStorage.getItem('projects') || '[]');
          const newProject = { 
            id: Date.now().toString(), 
            ...projectData,
            createdAt: new Date().toISOString() 
          };
          existingProjects.push(newProject);
          localStorage.setItem('projects', JSON.stringify(existingProjects));
        }

        // Sauvegarder dans Firestore
        await addDoc(collection(db, 'projects'), projectData);
        navigate('/projects');
      } catch (err) {
        console.error('Firestore error:', err);
        setError(t('pages.projects.create.error.save'));
        if (process.env.NODE_ENV === 'development') {
          // En dev, continuer même si Firestore échoue
          navigate('/projects');
          return;
        }
        throw err;
      }
    } catch (error) {
      console.error('Error creating project:', error);
      setError(t('pages.projects.create.error.general'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.projects.create.title')}
            </span>
          </h1>

          {(error || uploadError) && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error || uploadError}
            </div>
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="mb-6">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-primary h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                {t('common.uploading')}: {Math.round(uploadProgress)}%
              </p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.title')}
              </label>
              <input
                type="text"
                required
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.description')}
              </label>
              <textarea
                required
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                rows="4"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.type')}
              </label>
              <select
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="website">Website</option>
                <option value="data_architecture">Data Architecture</option>
                <option value="visualization">Visualization</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.technologies')}
              </label>
              <input
                type="text"
                required
                value={formData.technologies}
                onChange={(e) => setFormData(prev => ({ ...prev, technologies: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                placeholder="React, Firebase, Tailwind CSS..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.features')}
              </label>
              <input
                type="text"
                required
                value={formData.features}
                onChange={(e) => setFormData(prev => ({ ...prev, features: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
                placeholder="Feature 1, Feature 2, Feature 3..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.link')}
              </label>
              <input
                type="url"
                value={formData.link}
                onChange={(e) => setFormData(prev => ({ ...prev, link: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">
                {t('pages.projects.create.form.image')}
              </label>
              <input
                type="file"
                accept="image/*"
                required
                onChange={handleImageChange}
                className="w-full text-sm text-gray-500 dark:text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-primary file:text-white
                  hover:file:bg-primary/80
                  cursor-pointer"
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/projects')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.projects.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {loading ? t('pages.projects.common.saving') : t('pages.projects.common.save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

export const PdfPreviewModal = ({ book, onClose }) => {
  const { t } = useTranslation();
  
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
          <h3 className="text-xl font-bold">
            {book.title} - {t('pages.books.modal.preview_title')}
          </h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            {t('pages.books.modal.close')}
          </button>
        </div>
        <div className="p-6 overflow-y-auto max-h-[calc(90vh-100px)]">
          <iframe
            src={book.previewPdf}
            className="w-full h-[800px] border-0"
            title={book.title}
          />
        </div>
      </div>
    </div>
  );
};

import { motion } from 'framer-motion';
import { useState } from 'react';

const ExperienceShowcase = ({ experiences }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="relative">
      {/* Experience cards */}
      <div className="relative mx-auto max-w-[1200px] px-4 md:px-6 lg:px-20">
        {experiences.map((experience, index) => (
          <motion.div
            key={index}
            initial={false}
            animate={{
              opacity: index === activeIndex ? 1 : 0,
              x: index === activeIndex ? 0 : (index < activeIndex ? -50 : 50),
              scale: index === activeIndex ? 1 : 0.95,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut"
            }}
            className={`${
              index === activeIndex ? 'relative pointer-events-auto' : 'absolute inset-0 pointer-events-none'
            }`}
          >
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-4 md:p-6 lg:p-12 border border-gray-100 dark:border-gray-700">
              <div className="flex flex-col lg:grid lg:grid-cols-[300px,1fr] gap-6 lg:gap-16">
                {/* Logo Section */}
                <div className="relative group mx-auto lg:mx-0 w-full max-w-[200px] lg:max-w-none">
                  <motion.div
                    animate={{
                      scale: index === activeIndex ? [0.95, 1] : 0.95,
                    }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="relative z-10 bg-gradient-to-br from-primary/5 to-accent/5 rounded-xl p-6 md:p-8 shadow-lg transform-gpu transition-transform duration-300 group-hover:scale-105 border border-gray-100 dark:border-gray-600 overflow-hidden"
                  >
                    {experience.logo ? (
                      <div className="aspect-square relative">
                        <img 
                          src={experience.logo} 
                          alt={experience.title}
                          className="w-full h-full object-contain relative z-10"
                        />
                        <div className="absolute inset-0 bg-primary/5 rounded-lg group-hover:bg-transparent transition-colors duration-300" />
                      </div>
                    ) : (
                      <div className="aspect-square relative flex items-center justify-center">
                        <span className="text-4xl md:text-6xl font-bold text-primary/20">
                          {experience.title.charAt(0)}
                        </span>
                      </div>
                    )}
                    <div className="absolute inset-0 bg-gradient-to-br from-primary/30 to-accent/30 blur-xl transform-gpu transition-all duration-300 group-hover:blur-2xl" />
                  </motion.div>
                </div>

                {/* Content Section */}
                <div className="space-y-6 md:space-y-8">
                  <div className="space-y-3 md:space-y-4">
                    <motion.h3 
                      className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 dark:text-white"
                      animate={{ opacity: [0, 1], y: [20, 0] }}
                      transition={{ duration: 0.5, delay: 0.3 }}
                    >
                      {experience.title}
                    </motion.h3>
                    <motion.div
                      animate={{ opacity: [0, 1], y: [20, 0] }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                      className="flex flex-wrap gap-3 md:gap-4 items-center"
                    >
                      <span className="text-base md:text-lg lg:text-xl text-primary font-medium">
                        {experience.role}
                      </span>
                      <span className="px-3 py-1 md:px-4 md:py-1.5 bg-primary/10 text-primary rounded-full text-sm font-medium">
                        {experience.date}
                      </span>
                    </motion.div>
                  </div>

                  {experience.description && (
                    <motion.p
                      animate={{ opacity: [0, 1], y: [20, 0] }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                      className="text-sm md:text-base lg:text-lg text-gray-600 dark:text-gray-300"
                    >
                      {experience.description}
                    </motion.p>
                  )}

                  {Array.isArray(experience.achievements) && experience.achievements.length > 0 && (
                    <motion.div
                      animate={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5, delay: 0.6 }}
                      className="grid md:grid-cols-2 gap-3 md:gap-4 lg:gap-6"
                    >
                      {experience.achievements.map((achievement, i) => (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3, delay: 0.7 + (i * 0.1) }}
                          className="flex items-start gap-2 md:gap-3 group"
                        >
                          <div className="relative mt-1.5 flex-shrink-0">
                            <div className="w-1.5 h-1.5 md:w-2 md:h-2 lg:w-2.5 lg:h-2.5 rounded-full bg-primary group-hover:scale-150 transition-all duration-300" />
                            <div className="absolute inset-0 bg-primary/20 rounded-full blur group-hover:blur-md transition-all duration-300" />
                          </div>
                          <p className="text-xs md:text-sm lg:text-base text-gray-600 dark:text-gray-300 leading-relaxed">
                            {achievement}
                          </p>
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        ))}

        {/* Navigation arrows */}
        <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 flex justify-between pointer-events-none z-10">
          <button
            onClick={() => setActiveIndex(Math.max(0, activeIndex - 1))}
            className={`p-2 rounded-full bg-white dark:bg-gray-800 shadow-lg pointer-events-auto transform transition-all duration-300 -translate-x-1/2 ${
              activeIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:scale-110 hover:shadow-xl'
            }`}
            disabled={activeIndex === 0}
          >
            <svg className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => setActiveIndex(Math.min(experiences.length - 1, activeIndex + 1))}
            className={`p-2 rounded-full bg-white dark:bg-gray-800 shadow-lg pointer-events-auto transform transition-all duration-300 translate-x-1/2 ${
              activeIndex === experiences.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:scale-110 hover:shadow-xl'
            }`}
            disabled={activeIndex === experiences.length - 1}
          >
            <svg className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Navigation dots */}
      <div className="flex justify-center gap-2 md:gap-3 mt-6 md:mt-8">
        {experiences.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-2 h-2 md:w-2.5 md:h-2.5 rounded-full transition-all duration-300 ${
              index === activeIndex 
                ? 'bg-primary scale-125 ring-4 ring-primary/20' 
                : 'bg-gray-300 dark:bg-gray-600 hover:bg-primary/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceShowcase;

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db, storage } from '../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAdmin } from '../hooks/useAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Quill editor modules configuration
const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'font', 'size',
  'list', 'bullet',
  'align',
  'link', 'image', 'video'
];

export default function EditBlogPost() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isAdmin = useAdmin();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: 'ai',
    image: null,
    currentImageUrl: ''
  });

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin) {
      navigate('/blog');
      return;
    }

    const fetchPost = async () => {
      try {
        // En mode développement, essayer d'abord le localStorage
        if (process.env.NODE_ENV === 'development') {
          const localPosts = JSON.parse(localStorage.getItem('posts') || '[]');
          const post = localPosts.find(p => p.id === id);
          if (post) {
            setFormData({
              title: post.title || '',
              content: post.content || '',
              category: post.category || 'ai',
              image: null,
              currentImageUrl: post.image || ''
            });
            setLoading(false);
            return;
          }
        }

        // Si pas de données dans le localStorage ou en production, utiliser Firestore
        const postDoc = await getDoc(doc(db, 'posts', id));
        if (postDoc.exists()) {
          const post = postDoc.data();
          setFormData({
            title: post.title || '',
            content: post.content || '',
            category: post.category || 'ai',
            image: null,
            currentImageUrl: post.image || ''
          });
        } else {
          setError(t('pages.blog.edit.not_found'));
          setTimeout(() => navigate('/blog'), 3000);
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(t('pages.blog.edit.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, t, isAdmin, navigate]);

  const categories = [
    { value: 'ai', label: t('pages.blog.categories.ai') },
    { value: 'data_engineer', label: t('pages.blog.categories.data_engineer') },
    { value: 'algorithms', label: t('pages.blog.categories.algorithms') },
    { value: 'big_data', label: t('pages.blog.categories.big_data') },
    { value: 'sociology', label: t('pages.blog.categories.sociology') },
  ];

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      // Basic image validation
      if (!file.type.startsWith('image/')) {
        setError(t('pages.blog.edit.invalid_image'));
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError(t('pages.blog.edit.image_too_large'));
        return;
      }
      setFormData(prev => ({ ...prev, image: file }));
      setError(null);
    }
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Form validation
    if (!formData.title.trim()) {
      setError(t('pages.blog.edit.title_required'));
      return;
    }
    if (!formData.content.trim()) {
      setError(t('pages.blog.edit.content_required'));
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      let imageUrl = formData.currentImageUrl;
      
      if (formData.image) {
        try {
          const imageRef = ref(storage, `blog/${Date.now()}_${formData.image.name}`);
          const uploadResult = await uploadBytes(imageRef, formData.image);
          imageUrl = await getDownloadURL(uploadResult.ref);
        } catch (err) {
          console.error('Storage error:', err);
          // If storage fails in development, use the file directly
          if (process.env.NODE_ENV === 'development') {
            imageUrl = URL.createObjectURL(formData.image);
          } else {
            setError(t('pages.blog.edit.image_upload_error'));
            setSubmitting(false);
            return;
          }
        }
      }

      const updatedPost = {
        title: formData.title.trim(),
        content: formData.content.trim(),
        category: formData.category,
        image: imageUrl,
        readTime: Math.max(1, Math.ceil(formData.content.replace(/<[^>]*>/g, '').split(' ').length / 200)) + ' min read',
        updatedAt: new Date().toISOString()
      };

      // En mode développement, mettre à jour le localStorage
      if (process.env.NODE_ENV === 'development') {
        const localPosts = JSON.parse(localStorage.getItem('posts') || '[]');
        const updatedPosts = localPosts.map(post => 
          post.id === id ? { ...post, ...updatedPost } : post
        );
        localStorage.setItem('posts', JSON.stringify(updatedPosts));
      }

      // En production, mettre à jour Firestore
      if (process.env.NODE_ENV !== 'development') {
        const postRef = doc(db, 'posts', id);
        await updateDoc(postRef, updatedPost);
      }

      navigate(`/blog/${id}`);
    } catch (error) {
      console.error('Error updating post:', error);
      setError(t('pages.blog.edit.error'));
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
            <div className="text-center text-gray-600 dark:text-gray-300">
              {t('pages.blog.edit.loading')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.blog.edit.title')}
            </span>
          </h1>

          {error && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Current Image Preview */}
            {formData.currentImageUrl && (
              <div className="mb-4">
                <img 
                  src={formData.currentImageUrl} 
                  alt={formData.title}
                  className="w-full h-48 object-cover rounded-lg"
                />
              </div>
            )}

            {/* Image Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.blog.edit.image_label')}
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {t('pages.blog.edit.image_optional')}
              </p>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full text-sm text-gray-500 dark:text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-primary file:text-white
                  hover:file:bg-primary/80
                  cursor-pointer"
              />
            </div>

            {/* Category */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.blog.edit.category_label')}
              </label>
              <select
                value={formData.category}
                onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                {categories.map(cat => (
                  <option key={cat.value} value={cat.value}>
                    {cat.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.blog.edit.title_label')}
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Content */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.blog.edit.content_label')}
              </label>
              <div className="[&_.ql-editor]:min-h-[250px] [&_.ql-container]:rounded-b-lg [&_.ql-toolbar]:rounded-t-lg [&_.ql-toolbar]:bg-white dark:[&_.ql-toolbar]:bg-gray-700 [&_.ql-container]:bg-white dark:[&_.ql-container]:bg-gray-700 [&_.ql-toolbar]:border-gray-300 dark:[&_.ql-toolbar]:border-gray-600 [&_.ql-container]:border-gray-300 dark:[&_.ql-container]:border-gray-600 [&_.ql-toolbar]:border-b-gray-300 dark:[&_.ql-toolbar]:border-b-gray-600">
                <ReactQuill
                  theme="snow"
                  value={formData.content}
                  onChange={handleContentChange}
                  modules={modules}
                  formats={formats}
                  className="[&_.ql-editor]:text-gray-900 dark:[&_.ql-editor]:text-white"
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-4 mt-16">
              <button
                type="button"
                onClick={() => navigate('/blog')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {t('pages.blog.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {submitting ? t('pages.blog.edit.submitting') : t('pages.blog.edit.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

const categories = ['ai', 'psychology', 'algorithms', 'big_data', 'sociology', 'network_science'];

export default function CategorySelect({ value, onChange }) {
  const { t } = useTranslation();
  
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        {t('pages.books.create.category_label')}
      </label>
      <select
        value={value}
        onChange={onChange}
        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
      >
        {categories.map(category => (
          <option key={category} value={category}>
            {t(`pages.books.categories.${category}`)}
          </option>
        ))}
      </select>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import { useMultiImageUpload } from '../hooks/useMultiImageUpload';

export default function EditAlbum() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const { 
    uploadMultipleImages, 
    totalProgress, 
    error: uploadError,
    resetProgress 
  } = useMultiImageUpload(`albums/${id}`);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/photography');
      return;
    }

    const fetchAlbum = async () => {
      try {
        const albumDoc = await getDoc(doc(db, 'albums', id));
        if (albumDoc.exists()) {
          setFormData({ id: albumDoc.id, ...albumDoc.data() });
        } else {
          setError(t('pages.photography.edit.not_found'));
        }
      } catch (err) {
        console.error('Error fetching album:', err);
        setError(t('pages.photography.edit.fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchAlbum();
  }, [id, isAdmin, navigate, t]);

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
  }, [uploadError]);

  const handleDragStart = (e, index) => {
    if (e.target.closest('.delete-button')) {
      e.preventDefault();
      return;
    }
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.target.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    setDraggedIndex(null);
    e.target.style.opacity = '1';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if (draggedIndex === null) return;
    
    setFormData(prev => {
      const newPhotos = [...prev.photos];
      const [draggedPhoto] = newPhotos.splice(draggedIndex, 1);
      newPhotos.splice(dropIndex, 0, draggedPhoto);
      return { ...prev, photos: newPhotos };
    });
  };

  const handleDeleteClick = (e, index) => {
    e.stopPropagation();
    removePhoto(index);
  };

  const handlePhotoUpload = async (e) => {
    const files = Array.from(e.target.files);
    
    const validFiles = files.filter(file => {
      if (!file.type.startsWith('image/')) {
        setError(t('pages.photography.create.invalid_image'));
        return false;
      }
      if (file.size > 50 * 1024 * 1024) { // 50MB limit
        setError(t('pages.photography.create.image_too_large'));
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    setSubmitting(true);
    try {
      const processedPhotos = await Promise.all(validFiles.map(async (file) => {
        return {
          id: `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
          file,
          url: URL.createObjectURL(file),
          caption: '',
          isNew: true
        };
      }));

      setFormData(prev => ({
        ...prev,
        photos: [...(prev.photos || []), ...processedPhotos]
      }));
      setError(null);
    } catch (err) {
      console.error('Error processing photos:', err);
      setError(t('pages.photography.edit.photo_processing_error'));
    } finally {
      setSubmitting(false);
    }
  };

  const removePhoto = (index) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      setError(t('pages.photography.edit.title_required'));
      return;
    }
    if (!formData.description.trim()) {
      setError(t('pages.photography.edit.description_required'));
      return;
    }

    setSubmitting(true);
    setError(null);
    resetProgress();

    try {
      // Get new photos that need to be uploaded
      const newPhotos = formData.photos.filter(photo => photo.isNew);
      const existingPhotos = formData.photos.filter(photo => !photo.isNew);

      // Upload new photos
      let uploadedUrls = [];
      if (newPhotos.length > 0) {
        uploadedUrls = await uploadMultipleImages(newPhotos.map(photo => photo.file));
      }

      // Combine existing and new photos
      const updatedPhotos = [
        ...existingPhotos,
        ...uploadedUrls.map((url, index) => ({
          id: newPhotos[index].id,
          url,
          caption: newPhotos[index].caption || ''
        }))
      ];

      // Update Firestore
      await updateDoc(doc(db, 'albums', id), {
        title: formData.title.trim(),
        description: formData.description.trim(),
        date: formData.date,
        photos: updatedPhotos,
        updatedAt: new Date()
      });

      navigate('/photography');
    } catch (error) {
      console.error('Error updating album:', error);
      setError(t('pages.photography.edit.error'));
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.photography.loading')}
          </div>
        </div>
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-red-600 dark:text-red-400">
            {error || t('pages.photography.edit.not_found')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12">
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8">
          <h1 className="text-3xl font-bold mb-8 text-center">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.photography.edit.title')}
            </span>
          </h1>

          {error && (
            <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
              {error}
            </div>
          )}

          {totalProgress > 0 && totalProgress < 100 && (
            <div className="mb-6">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-primary h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${totalProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                {t('pages.photography.edit.uploading')}: {totalProgress}%
              </p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.edit.title_label')}
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.edit.description_label')}
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.edit.date_label')}
              </label>
              <input
                type="date"
                value={formData.date}
                onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                required
              />
            </div>

            {/* Add More Photos */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                {t('pages.photography.edit.add_photos_label')}
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handlePhotoUpload}
                disabled={submitting}
                className="w-full text-sm text-gray-500 dark:text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-primary file:text-white
                  hover:file:bg-primary/80
                  cursor-pointer
                  disabled:opacity-50"
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {submitting ? t('pages.photography.edit.processing_photos') : t('pages.photography.create.photos_help')}
              </p>
            </div>

            {/* Photos Preview with drag and drop */}
            {formData.photos && formData.photos.length > 0 && (
              <>
                <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                  {t('pages.photography.edit.drag_help')}
                </p>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {formData.photos.map((photo, index) => (
                    <div
                      key={photo.id}
                      className={`relative group cursor-move ${
                        draggedIndex === index ? 'opacity-40' : ''
                      }`}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <img
                        src={photo.url}
                        alt={`${formData.title} - ${index + 1}`}
                        className="w-full h-32 object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={(e) => handleDeleteClick(e, index)}
                        className="delete-button absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer z-10"
                        draggable="false"
                      >
                        ×
                      </button>
                      <div className="absolute inset-0 border-2 border-transparent group-hover:border-primary rounded-lg transition-colors" />
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/photography')}
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                disabled={submitting}
              >
                {t('pages.photography.common.cancel')}
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 disabled:opacity-50"
              >
                {submitting ? t('pages.photography.edit.submitting') : t('pages.photography.edit.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { CameraIcon, ChevronLeftIcon, ChevronRightIcon, PencilIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { db } from '../firebase/config';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import TranslationWarning from '../components/TranslationWarning';

const Carousel = ({ photos = [], currentIndex, setCurrentIndex }) => {
  // If no photos, show placeholder
  if (!photos.length) {
    return (
      <div className="relative w-full aspect-[3/2] bg-gray-900 rounded-xl overflow-hidden flex items-center justify-center">
        <CameraIcon className="w-20 h-20 text-gray-600" />
      </div>
    );
  }

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % photos.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + photos.length) % photos.length);
  };

  return (
    <div className="relative w-full aspect-[3/2] bg-gray-900 rounded-t-2xl overflow-hidden group">
      {/* Current Image */}
      <div className="w-full h-full flex items-center justify-center">
        <img
          src={photos[currentIndex].url}
          alt={photos[currentIndex].caption || ''}
          className="w-full h-full object-contain"
        />
      </div>

      {/* Navigation Arrows */}
      {photos.length > 1 && (
        <>
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </>
      )}

      {/* Caption */}
      {photos[currentIndex].caption && (
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
          <p className="text-white text-lg">
            {photos[currentIndex].caption}
          </p>
        </div>
      )}

      {/* Dots Navigation */}
      {photos.length > 1 && (
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
          {photos.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentIndex ? 'bg-white w-4' : 'bg-white/50'
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const PhotoAlbum = ({ album, isAdmin, onEdit, onDelete }) => {
  const { t, i18n } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!album) return null;

  const isTranslationMissing = i18n.language === 'en' && (!album.titleEn || !album.descriptionEn);
  const displayTitle = i18n.language === 'en' && album.titleEn ? album.titleEn : album.title;
  const displayDescription = i18n.language === 'en' && album.descriptionEn ? album.descriptionEn : album.description;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg max-w-[1200px] mx-auto">
      <Carousel
        photos={album.photos || []}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="text-2xl font-bold">{displayTitle}</h3>
            {isTranslationMissing && <TranslationWarning />}
          </div>
          <span className="text-gray-500 dark:text-gray-400">{album.date}</span>
        </div>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          {displayDescription}
        </p>
        <div className="mt-4 flex items-center justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {(album.photos || []).length} {t('pages.photography.labels.photos_count')}
          </span>
          {isAdmin && (
            <div className="flex space-x-2">
              <button
                onClick={() => onEdit(album.id)}
                className="p-2 text-blue-600 hover:bg-blue-50 dark:text-blue-400 dark:hover:bg-blue-900/50 rounded-lg transition-colors"
              >
                <PencilIcon className="w-5 h-5" />
              </button>
              <button
                onClick={() => onDelete(album.id)}
                className="p-2 text-red-600 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-900/50 rounded-lg transition-colors"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
        {album.photos && album.photos.length > 0 && (
          <div className="mt-4 -mx-6 px-6">
            <div className="overflow-x-auto">
              <div className="inline-flex gap-3 p-2 min-w-full">
                {album.photos.map((photo, index) => (
                  <div
                    key={photo.id}
                    className={`w-[120px] shrink-0 rounded-lg cursor-pointer transition-all duration-300 ${
                      index === currentIndex 
                        ? 'ring-1 ring-primary ring-offset-1 ring-offset-white dark:ring-offset-gray-800' 
                        : 'opacity-50 hover:opacity-100'
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  >
                    <div className="aspect-[3/2] rounded-lg overflow-hidden">
                      <img
                        src={photo.url}
                        alt={photo.caption || ''}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function Photography() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useAdmin();
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const albumsSnapshot = await getDocs(collection(db, 'albums'));
        const albumsData = albumsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort albums by date in descending order (most recent first)
        const sortedAlbums = albumsData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
        setAlbums(sortedAlbums);
      } catch (err) {
        console.error('Error fetching albums:', err);
        setError(t('pages.photography.errors.fetch_failed'));
      } finally {
        setLoading(false);
      }
    };

    fetchAlbums();
  }, [refreshKey, location.pathname, t]);

  const handleEdit = (albumId) => {
    navigate(`/photography/edit/${albumId}`);
  };

  const handleDelete = async (albumId) => {
    if (window.confirm(t('pages.photography.confirm_delete'))) {
      try {
        await deleteDoc(doc(db, 'albums', albumId));
        setRefreshKey(prev => prev + 1); // Trigger refresh
      } catch (err) {
        console.error('Error deleting album:', err);
        setError(t('pages.photography.errors.delete_failed'));
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="container-custom py-12">
          <div className="text-center text-gray-600 dark:text-gray-300">
            {t('pages.photography.loading')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="max-w-[1200px] mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
              {t('pages.photography.header.title')}
            </span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('pages.photography.header.subtitle')}
          </p>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
            {error}
          </div>
        )}

        {isAdmin && (
          <div className="mb-8">
            <button
              onClick={() => navigate('/photography/create')}
              className="flex items-center space-x-2 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
            >
              <PlusIcon className="w-5 h-5" />
              <span>{t('pages.photography.create_album')}</span>
            </button>
          </div>
        )}

        {/* Photo Albums */}
        <div className="space-y-12">
          {albums.map(album => (
            <PhotoAlbum
              key={`${album.id}_${refreshKey}`}
              album={album}
              isAdmin={isAdmin}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </div>

        {albums.length === 0 && (
          <div className="text-center text-gray-500 dark:text-gray-400 py-12">
            {t('pages.photography.no_albums')}
          </div>
        )}
      </div>
    </div>
  );
}

export default function FormField({ 
  type = 'text', 
  label, 
  value, 
  onChange, 
  required = false, 
  options = [], 
  ...props 
}) {
  const baseClasses = "w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white";

  const renderField = () => {
    switch (type) {
      case 'textarea':
        return (
          <textarea
            value={value}
            onChange={onChange}
            className={baseClasses}
            required={required}
            {...props}
          />
        );
      case 'select':
        return (
          <select
            value={value}
            onChange={onChange}
            className={baseClasses}
            required={required}
            {...props}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type={type}
            value={value}
            onChange={onChange}
            className={baseClasses}
            required={required}
            {...props}
          />
        );
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        {label}
      </label>
      {renderField()}
    </div>
  );
}

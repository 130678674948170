import { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import {
  BriefcaseIcon,
  SparklesIcon,
  HeartIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';

import {
  TechGrid,
  TechModal,
  PassionCard,
  SectionHeader,
  CenteredSectionHeader,
  BookSection,
  ExperienceShowcase
} from '../components/about';

// Import ProjectCard from projects instead of about
import ProjectCard from '../components/projects/ProjectCard';

import { techDetails, creativeTools } from '../data';

import auchanLogo from '../assets/images/auchan.jpg';
import tedxLogo from '../assets/images/TEDx.png';
import estpLogo from '../assets/images/ESTP_Logo.png';
import bookCover from '../assets/images/livre.png';

const passions = [
  { key: 'violin', icon: '🎻' },
  { key: 'fitness', icon: '💪' },
  { key: 'beach_volleyball', icon: '🏐' },
  { key: 'reading', icon: '📚' },
  { key: 'chess', icon: '♟️' },
  { key: 'coding', icon: '💻' }
];

export default function About() {
  const [selectedTech, setSelectedTech] = useState(null);
  const [projects, setProjects] = useState([]);
  const { t } = useTranslation();

  // Sort techDetails by rating in descending order
  const sortedTechDetails = Object.fromEntries(
    Object.entries(techDetails)
      .sort(([, a], [, b]) => b.rating - a.rating)
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // En mode développement, essayer d'abord le localStorage
        if (process.env.NODE_ENV === 'development') {
          const localProjects = JSON.parse(localStorage.getItem('projects') || '[]');
          if (localProjects.length > 0) {
            setProjects(localProjects);
            return;
          }
        }

        // Si pas de données dans le localStorage ou en production, utiliser Firestore
        const q = query(collection(db, 'projects'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const projectsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProjects(projectsData);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const experiences = [
    {
      logo: auchanLogo,
      title: "Auchan Retail France",
      role: t('pages.about.experiences.auchan.role'),
      date: t('pages.about.experiences.auchan.date'),
      description: t('pages.about.experiences.auchan.description'),
      achievements: t('pages.about.experiences.auchan.achievements', { returnObjects: true })
    },
    {
      logo: tedxLogo,
      title: "TEDxESTP Paris",
      role: t('pages.about.experiences.tedx.role'),
      date: t('pages.about.experiences.tedx.date'),
      achievements: t('pages.about.experiences.tedx.achievements', { returnObjects: true })
    },
    {
      logo: estpLogo,
      title: "ESTP Paris",
      role: t('pages.about.experiences.estp.role'),
      date: t('pages.about.experiences.estp.date'),
      achievements: t('pages.about.experiences.estp.achievements', { returnObjects: true })
    },
    {
      title: "Pothier High School",
      role: t('pages.about.experiences.pothier.role'),
      date: t('pages.about.experiences.pothier.date'),
      achievements: t('pages.about.experiences.pothier.achievements', { returnObjects: true })
    }
  ];

  const handleTechClick = (tech, type) => {
    setSelectedTech({ ...tech, type });
  };

  // Dummy function for project deletion (not used in About page)
  const handleProjectDelete = () => {};

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container-custom py-24 pt-28 md:pt-32">
        <div className="space-y-32 md:space-y-40">
          {/* Professional Journey Section */}
          <section>
            <SectionHeader 
              icon={BriefcaseIcon} 
              title={t('pages.about.sections.professional')} 
            />
            <div className="mt-12">
              <ExperienceShowcase experiences={experiences} />
            </div>
          </section>

          {/* Book Section */}
          <section className="relative z-10">
            <SectionHeader 
              icon={SparklesIcon} 
              title={t('pages.about.sections.published')} 
            />
            <div className="mt-12">
              <BookSection bookCover={bookCover} />
            </div>
          </section>

          {/* Projects Section */}
          <section>
            <SectionHeader 
              icon={RocketLaunchIcon} 
              title={t('pages.about.sections.projects')} 
            />
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
              {projects.map((project) => (
                <ProjectCard 
                  key={project.id}
                  project={project}
                  onDelete={handleProjectDelete}
                />
              ))}
            </div>
          </section>

          {/* Technical Skills Section */}
          <section>
            <CenteredSectionHeader 
              title={t('pages.about.sections.technical')}
              subtitle={t('pages.about.sections.technical_subtitle')}
            />
            <div className="mt-12">
              <TechGrid 
                items={sortedTechDetails} 
                onTechClick={(tech) => handleTechClick(tech, 'tech')} 
                type="tech" 
              />
            </div>
          </section>

          {/* Passions Section */}
          <section>
            <SectionHeader 
              icon={HeartIcon} 
              title={t('pages.about.sections.passions')} 
            />
            <div className="mt-12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
              {passions.map((passion) => (
                <PassionCard 
                  key={passion.key}
                  passionKey={passion.key}
                  icon={passion.icon}
                />
              ))}
            </div>
          </section>

          {/* Creative Tools Section */}
          <section>
            <CenteredSectionHeader 
              title={t('pages.about.sections.creative')}
              subtitle={t('pages.about.sections.creative_subtitle')}
            />
            <div className="mt-12">
              <TechGrid 
                items={creativeTools} 
                onTechClick={(tech) => handleTechClick(tech, 'creative')} 
                type="creative" 
              />
            </div>
          </section>
        </div>

        {/* Tech Details Modal */}
        <AnimatePresence>
          {selectedTech && (
            <TechModal
              tech={selectedTech}
              onClose={() => setSelectedTech(null)}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

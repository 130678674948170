import { useTranslation } from 'react-i18next';
import FileUpload from './FileUpload';
import FormField from './FormField';
import CategorySelect from './CategorySelect';

export default function BookForm({ 
  formData, 
  setFormData, 
  uploadProgress, 
  handleCoverChange, 
  handlePdfChange, 
  error 
}) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      {/* Book Type */}
      <FormField
        type="select"
        label={t('pages.books.create.type_label')}
        value={formData.type}
        onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
        options={[
          { value: 'my_book', label: t('pages.books.create.type_my_book') },
          { value: 'recommended', label: t('pages.books.create.type_recommended') }
        ]}
      />

      {/* Cover Upload */}
      <FileUpload
        label={t('pages.books.create.cover_label')}
        accept="image/*"
        onChange={handleCoverChange}
        progress={uploadProgress.cover}
        required
      />

      {/* Title */}
      <FormField
        type="text"
        label={t('pages.books.create.title_label')}
        value={formData.title}
        onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
        required
      />

      {/* Description */}
      <FormField
        type="textarea"
        label={t('pages.books.create.description_label')}
        value={formData.description}
        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
        rows={5}
        required
      />

      {/* Reading Time */}
      <FormField
        type="number"
        label="Temps de lecture (minutes)"
        value={formData.readingTime}
        onChange={(e) => setFormData(prev => ({ ...prev, readingTime: e.target.value }))}
        min="1"
        required
      />

      {/* Year */}
      <FormField
        type="number"
        label={t('pages.books.create.year_label')}
        value={formData.year}
        onChange={(e) => setFormData(prev => ({ ...prev, year: e.target.value }))}
        min="1900"
        max={new Date().getFullYear()}
      />

      {formData.type === 'recommended' && (
        <>
          {/* Author */}
          <FormField
            type="text"
            label={t('pages.books.create.author_label')}
            value={formData.author}
            onChange={(e) => setFormData(prev => ({ ...prev, author: e.target.value }))}
          />
          
          {/* Category */}
          <CategorySelect
            value={formData.category}
            onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
          />
        </>
      )}

      {formData.type === 'my_book' && (
        <FileUpload
          label={t('pages.books.create.pdf_label')}
          accept="application/pdf"
          onChange={handlePdfChange}
          progress={uploadProgress.pdf}
          required
        />
      )}
    </div>
  );
}

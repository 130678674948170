import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import { useAdmin } from '../hooks/useAdmin';
import RichTextEditor from '../components/RichTextEditor';

// Helper function to generate Firestore-like IDs
const generateFirestoreId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 20; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export default function CreateBlogPost() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: 'ai',
    image: null
  });

  // Redirect if not admin
  if (!isAdmin) {
    navigate('/blog');
    return null;
  }

  const categories = [
    { value: 'ai', label: t('pages.blog.categories.ai') },
    { value: 'data_engineer', label: t('pages.blog.categories.data_engineer') },
    { value: 'algorithms', label: t('pages.blog.categories.algorithms') },
    { value: 'big_data', label: t('pages.blog.categories.big_data') },
    { value: 'sociology', label: t('pages.blog.categories.sociology') },
  ];

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({ ...prev, image: e.target.files[0] }));
    }
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const uploadImage = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await auth.currentUser.getIdToken();
        const reader = new FileReader();
        
        reader.onload = async () => {
          try {
            const base64data = reader.result;
            const response = await fetch('https://us-central1-kohnnect-prod-2024.cloudfunctions.net/uploadImage', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                file: base64data,
                contentType: file.type
              })
            });

            if (!response.ok) {
              throw new Error('Upload failed');
            }

            const data = await response.json();
            resolve(data.url);
          } catch (error) {
            console.error('Upload error:', error);
            reject(error);
          }
        };

        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Token error:', error);
        reject(error);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.image) {
      alert(t('pages.blog.create.image_required'));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      let imageUrl;
      
      try {
        setUploadProgress(10);
        imageUrl = await uploadImage(formData.image);
        setUploadProgress(70);
      } catch (err) {
        console.error('Storage error:', err);
        imageUrl = 'https://images.unsplash.com/photo-1555949963-aa79dcee981c?auto=format&fit=crop&q=80';
      }

      const postData = {
        title: formData.title,
        content: formData.content,
        category: formData.category,
        image: imageUrl,
        date: new Date().toISOString(),
        readTime: Math.max(1, Math.ceil(formData.content.replace(/<[^>]*>/g, '').split(' ').length / 200)) + ' min read'
      };

      setUploadProgress(80);

      try {
        let postId;
        if (process.env.NODE_ENV === 'development') {
          postId = generateFirestoreId();
          const existingPosts = JSON.parse(localStorage.getItem('posts') || '[]');
          const newPost = { id: postId, ...postData };
          existingPosts.push(newPost);
          localStorage.setItem('posts', JSON.stringify(existingPosts));
        }

        const docRef = await addDoc(collection(db, 'posts'), postData);
        postId = docRef.id;

        setUploadProgress(100);
        navigate(`/blog/${postId}`);
      } catch (err) {
        console.error('Firestore error:', err);
        setError(t('pages.blog.create.offline_mode'));
        
        if (process.env.NODE_ENV === 'development') {
          setTimeout(() => {
            navigate('/blog');
          }, 1500);
          return;
        }
        throw err;
      }
    } catch (error) {
      console.error('Error creating post:', error);
      setError(t('pages.blog.create.error'));
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="container-custom py-12 max-w-3xl">
        <h1 className="text-4xl font-bold mb-8 text-center">
          <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
            {t('pages.blog.create.title')}
          </span>
        </h1>

        {error && (
          <div className="mb-6 p-4 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 rounded-lg">
            {error}
          </div>
        )}

        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mb-6">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-primary h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
              {t('pages.blog.create.uploading')}: {Math.round(uploadProgress)}%
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6 bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-lg">
          {/* Image Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              {t('pages.blog.create.image_label')}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full text-sm text-gray-500 dark:text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-primary file:text-white
                hover:file:bg-primary/80
                cursor-pointer"
              required
            />
          </div>

          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              {t('pages.blog.create.category_label')}
            </label>
            <select
              value={formData.category}
              onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            >
              {categories.map(cat => (
                <option key={cat.value} value={cat.value}>
                  {cat.label}
                </option>
              ))}
            </select>
          </div>

          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              {t('pages.blog.create.title_label')}
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              required
            />
          </div>

          {/* Content */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              {t('pages.blog.create.content_label')}
            </label>
            <RichTextEditor
              value={formData.content}
              onChange={handleContentChange}
              placeholder={t('pages.blog.create.content_placeholder')}
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-primary hover:bg-primary/80 text-white font-bold py-3 px-6 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? t('pages.blog.create.submitting') : t('pages.blog.create.submit')}
          </button>
        </form>
      </div>
    </div>
  );
}

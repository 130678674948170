export const NetworkIcon = ({ className = "w-5 h-5" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <circle cx="12" cy="12" r="3" />
    <circle cx="19" cy="5" r="2" />
    <circle cx="5" cy="19" r="2" />
    <circle cx="19" cy="19" r="2" />
    <circle cx="5" cy="5" r="2" />
    <line x1="12" y1="9" x2="12" y2="5" />
    <line x1="15" y1="12" x2="19" y2="12" />
    <line x1="12" y1="15" x2="12" y2="19" />
    <line x1="9" y1="12" x2="5" y2="12" />
    <line x1="7" y1="7" x2="10" y2="10" />
    <line x1="14" y1="14" x2="17" y2="17" />
    <line x1="14" y1="10" x2="17" y2="7" />
    <line x1="7" y1="17" x2="10" y2="14" />
  </svg>
);

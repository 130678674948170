import { useState } from 'react';
import { auth } from '../firebase/config';

export const useImageUpload = (folder = 'blog') => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  const uploadImage = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        setError(null);
        setUploadProgress(10);
        
        // Get the current user's ID token
        const token = await auth.currentUser.getIdToken();

        // Convert file to base64
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const base64data = reader.result;
            
            // Call the Cloud Function
            const response = await fetch('https://us-central1-kohnnect-prod-2024.cloudfunctions.net/uploadImage', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                file: base64data,
                contentType: file.type,
                folder: folder
              })
            });

            if (!response.ok) {
              throw new Error('Upload failed');
            }

            setUploadProgress(90);
            const data = await response.json();
            setUploadProgress(100);
            resolve(data.url);
          } catch (error) {
            console.error('Upload error:', error);
            setError(error.message);
            reject(error);
          }
        };

        reader.onerror = (error) => {
          setError(error.message);
          reject(error);
        };
        
        reader.readAsDataURL(file);
      } catch (error) {
        console.error('Token error:', error);
        setError(error.message);
        reject(error);
      }
    });
  };

  return {
    uploadImage,
    uploadProgress,
    error,
    setError,
    setUploadProgress
  };
};

const CenteredSectionHeader = ({ title, subtitle }) => (
  <div className="text-center mb-12">
    <h2 className="text-3xl font-bold mb-4">
      <span className="bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
        {title}
      </span>
    </h2>
    {subtitle && (
      <p className="text-gray-600 dark:text-gray-300">
        {subtitle}
      </p>
    )}
  </div>
);

export default CenteredSectionHeader;
